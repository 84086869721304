import React, { useState, useContext, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import axios from 'axios';
import {AppContext} from "../../AppContext";

const ResetPassword = (props) => {

    // Retrieve global configuration from application context:s
    const { config } = useContext(AppContext);
    // Retrieve history router for redirecting the user:
    const [history] = useState(useHistory());

    const [emailAddress, setEmailAddress] = useState("");
    const [executeRequest, setExecuteRequest] = useState(false);

    useEffect(() => {
        if(executeRequest === true) {
            const action = async () => {
                const response = await axios.post(config.api.base + '/reset', { username: emailAddress })
                history.push("/reset-password-started")
            }
            action();
        }
    }, [executeRequest])


    function onChangeEmail(args) {
        setEmailAddress(args.target.value);
    }

    function onResetPassword(args) {
         setExecuteRequest(true);
    }

    return (
        <div className="min-h-screen flex justify-center bg-gray-50 pt-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Password Reset
                </h2>
                <p className="text-center text-lg text-gray-600">
                    Please enter your email address:
                </p>
                <div>
                    <input id="email" name="email" type="email" autoComplete="email"
                           required
                           value={emailAddress}
                           onChange={onChangeEmail}
                           className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                           placeholder="Email"/>
                </div>
                <div>
                    <button onClick={onResetPassword} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Reset Password
                    </button>
                </div>
            </div>
        </div>
    )

}

export default ResetPassword;