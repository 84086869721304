import React from 'react';
import { NavLink } from 'react-router-dom'

const Dashview = ({ children, ...props}) => {

    // Pass the component props down to its children:
    const children_with_props = React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {...props})
    })

    // svg paths from heroicons
    return (
        <div className={"min-h-screen bg-gray-50 "}>
            <div className={"fixed h-full w-36 pt-28 border border-solid"}>
                <div className={"flex ml-4"}>
                    <svg className={"h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    <NavLink to={"/admin/dashboard"} className={"pl-2"}>
                        Home
                    </NavLink>
                </div>
                <div className={"flex ml-4 mt-4"}>
                    <svg className={"h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth={2} d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                    </svg>
                    <NavLink to={"/admin/dashboard/orders"} className={"pl-2"}>
                        All Orders
                    </NavLink>
                </div>
                <div className={"flex ml-4 mt-4"}>
                    <svg className={"h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth={2} d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                    </svg>
                    <NavLink to={"/admin/dashboard/users"} className={"pl-2"}>
                        Accounts
                    </NavLink>
                </div>
                <div className={"flex ml-4 mt-4"}>
                    <svg className={"h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth={1.75} d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <NavLink to={"/admin/dashboard/pricing"} className={"pl-2"}>
                        Pricing
                    </NavLink>
                </div>
                { 
                /** unused menu button, admin/dashboard/materials does not exist - edited 10/8/2024 ez
                <div className={"flex ml-4 mt-4"}>
                    <svg className={"h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <NavLink to={"/admin/dashboard/materials"} className={"pl-2"}>
                        Materials
                    </NavLink>
                </div>
                */
                }
            </div>
            <div className={"pl-40 w-full pt-24 pr-4"}>
                { children_with_props }
            </div>
        </div>
    )
}

export default Dashview;