import React, {useContext, useState, useEffect, useRef} from 'react';
import Dashview from 'paths/admin/dashboard/Dashview';
import axios from 'axios';
import {AppContext} from 'AppContext';
import {Redirect, useHistory, useParams} from "react-router-dom";
import { NavLink } from 'react-router-dom'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import ApxEditAccount from 'comps/ApxEditAccount';



const Content = (props) => {
    // Need the account id from the URL parameters:
    const params = useParams();
    // Need the user's identity and global application config:
    const { identity, config } = useContext(AppContext);

    const [account, set_account] = useState(null);
    const [pricing, set_pricing] = useState([]);

    // Retrieve relevant records for this account:
    useEffect(() => {
        const action = async () => {
            const account_api_response = await axios.get(config.api.base + '/admin/accounts/' + params.id);
            set_account(account_api_response.data[0]);
            const pricing_api_response = await axios.get(config.api.base + '/pricing/tables');
            set_pricing(pricing_api_response.data);
            console.log(pricing_api_response.data);
        }
        action();
    }, [])

    function onChangeFirstName(args) {
        account.first_name = args.target.value;
        set_account({...account})
    }

    function onChangeLastName(args) {
        account.last_name = args.target.value;
        set_account({...account})
    }

    function onChangeCompany(args) {
        account.company = args.target.value;
        set_account({ ...account})
    }

    function onChangeEmail(args) {
        account.email = args.target.value;
        set_account({...account})
    }

    function onChangePhone(args) {
        account.phone = args.target.value;
        set_account({...account})
    }

    function onChangePriceTable(args) {
        account.pricing_id = args.target.value;
        set_account({ ...account})
    }

    function onSubmit(args) {
        args.preventDefault();
        const action = async () => {
            try {
                const response = await axios.put(config.api.base + '/admin/accounts/' + params.id, account);
                console.log(response);
            } catch (x) {

            }
        }
        action();
    }

    if(account === null) {
        return null;
    }

    return(
        <div className={"container sm:w-4/5 mx-auto mt-8"}>
            <div className="w-full space-y-8">
                <div>
                    <span>
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                        Edit Account:
                    </h2>
                    <h2 className={"font-normal text-3xl text-gray-500" }>
                        {params.id}
                    </h2>
                    </span>
                </div>
                <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                    <div className={"flex"}>
                    <div className={"w-2/4 border-r-2 pr-4 space-y-4 border-dashed"}>
                    <div className={"flex space-x-6"}>
                        <div className={"w-full"}>
                            <label htmlFor="first-name">First name</label>
                            <input id="first-name" name="firstName" error={"true"}  type="text" autoComplete="given-name"
                                   value={account.first_name} required
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="First name"
                                   onChange={onChangeFirstName}
                            />
                        </div>
                        <div className={"w-full"}>
                            <label htmlFor="last-name">Last name</label>
                            <input id="last-name" name="lastName" type="text" autoComplete="family-name" required
                                   value={account.last_name}
                                   onChange={onChangeLastName}
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="Last name"/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="company">Company</label>
                        <input id="company" name="company" type="text" autoComplete="company"
                               required
                               value={account.company}
                               onChange={onChangeCompany}
                               className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Company"/>
                    </div>
                    <div>
                        <label htmlFor="email">Email address</label>
                        <input id="email" name="email" type="email" autoComplete="email"
                               required
                               value={account.email}
                               onChange={onChangeEmail}
                               className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Email"/>
                    </div>
                    <div>
                        <label htmlFor="phone">Phone number</label>
                        <input id="phone" name="email" type="text" autoComplete="phone"
                               required
                               value={account.phone}
                               onChange={onChangePhone}
                               className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Phone number"/>
                    </div>
                    </div>
                    <div className={"flex w-2/4  space-x-6 pl-4"}>
                        <div className={"w-full"}>
                            <div>
                                <label htmlFor="price_table" className="block text-sm font-medium text-gray-700">
                                    Pricing Table:
                                </label>
                                <select
                                    id="price_table"
                                    name="price_table"
                                    autoComplete="price_table"
                                    value={account.pricing_id}
                                    onChange={onChangePriceTable}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    {pricing.map((item, index) => {
                                        console.log(item);
                                        return <option key={index} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className={"w-2/4"}>
                        <button type={"submit"} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            SAVE
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const Editor = (props) => {
    return (<Dashview {...props}><Content {...props}/></Dashview>);
}

export default Editor;