import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { v4 } from 'uuid';
import axios from 'axios';
import ApxBusy from '../../comps/ApxBusy';
import ApxUpload from '../../comps/ApxUpload';
import {AppContext} from 'AppContext';
import Apx3D from 'comps/Apx3D'

const Appraiser2 = (props) => {

    const { identity, config } = useContext(AppContext);
    //const id = props.computedMatch.params.id;
    const { id } = useParams();

    const history = useHistory();

    const [state, setState] = useState({ busy: false, action: "init", data: {}});
    const [order, setOrder] = useState({
        parts: [],
        invoiceNumber: "",
        invoiceFile: "",
        invoiceFilename: "",
    });

    const [total, setTotal] = useState(0);

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadingInvoice, setUploadingInvoice] = useState(false);

    // Pricing data is loaded from the database when the component
    // is initialized for the first time:
    const [pricing, setPricing] = useState([]);

    // Material data is loaded from the database when the component
    // is initialized for the first time:
    const [materials, setMaterials] = useState([]);

    useEffect(() => {
        switch(state.action) {
            case "init" :
                onEnterStateInit(state.data);
                break;
            case "ready":
                break;
            case "upload":
                onEnterStateUpload(state.data);
                break;
            case "finalize":
                onEnterStateFinalize(state.data);
                break;
            case "redirect" :
                onEnterStateRedirect(state.data);
                break;
            case "upload_invoice" :
                onEnterStateUploadInvoice(state.data);
                break;
            default:
                break;
        }

    }, [state])

    function onEnterStateInit(context) {

        const action = async () => {

            // Retrieve pricing details from the database:
            let rxPricing = await axios.get(config.api.base + '/pricing');
            console.log(rxPricing);
            setPricing(rxPricing.data.pricing);

            // Retrieve material specifications from the database:
            let rxMaterials = await axios.get(config.api.base + '/materials');
            setMaterials(rxMaterials.data);

            // If we're not creating a new quote, then we need to attempt
            // to load an existing one from the database:
            if (id != 'new') {
                try {
                    const response = await axios.get(config.api.base + '/quotes/' + id)
                    console.log("EXISTING QUOTE RESPONSE:")
                    console.log(response);
                    setOrder(response.data.metadata)
                } catch(x) {

                }
            }

            setState({busy: false, action: "ready", data: {}});
        }
        action();
    }

    function onEnterStateUpload(data) {
        const action = async () => {
            // Retrieve the files and send them to the server
            const formData = new FormData();
            const partData = [];

            for (let i = 0; i != data.length; i++) {

                const generatedName = v4() + '.' +  data[i].name.split('.').pop();
                formData.append("file-" + i, data[i], generatedName);
                partData.push({
                    file: data[i].name,
                    path: '/temp/images/' + generatedName,
                    price: 0,
                    units: "UNITS_IN",
                    volume: 0,
                    material: materials[0].id,
                    finish: "NONE",
                    quantity: 1
                })
            }

            // Send the files to the server.
            try {
                setUploading(true);
                const response = await axios.post(config.api.base + '/temp/images', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: onUploadProgress
                })
            } catch(x) {
                console.log(x);
            }

            setUploading(false);

            // After the parts have been sent to the server
            // we can update the order object:
            for(let i=0;i!=partData.length;i++) {
                order.parts.push(partData[i]);
            }

            setOrder({...order});
        }
        action();
    }

    function onEnterStateUploadInvoice(data) {
        const action = async () => {
            // Retrieve the files and send them to the server
            const formData = new FormData();

            // The upload dialog should only allow one file to be selected
            // so we can just use the zero index into the data array:
            const generatedName = v4() + '.' +  data[0].name.split('.').pop();
            formData.append("file-" + 0, data[0], generatedName);

            // Send the files to the server.
            try {

                setUploading(true);
                const response = await axios.post(config.api.base + '/temp/images', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: onUploadProgress
                })

                // We now have the original filename and the generated name
                // that is used to identify the file on the server. We need
                // to add both of those fields to the order record:
                order.invoiceFile = "/temp/images/" + generatedName;
                order.invoiceFilename = data[0].name
                setOrder({...order});
                setUploading(false);

            } catch(x) {
                console.log(x);
            }
        }

        action();
    }

    function onUploadProgress(e) {
        setProgress(100 * (e.loaded / e.total))
    }

    function onEnterStateFinalize(context) {
        const action = async () => {
            try {
                const record = {
                    metadata : {
                        parts: [...order.parts],
                        invoiceNumber: order.invoiceNumber,
                        invoiceFile: order.invoiceFile,
                        invoiceFilename: order.invoiceFilename
                    }
                }
                const response = axios.post(config.api.base + "/quotes", record )
                setState({ busy: false, action: "redirect", data: { path: "/quotes/submitted"} })
            } catch(x) {
                throw "Unable to upload quote to server"
            }
        }
        action();
    }

    function onEnterStateRedirect(context) {
        history.push(context.path);
    }

    function onClickUpload(e) {

    }

    function onFilesDropped(files) {
        setState({ busy: true, action: "upload", data: files});
    }

    function onChangePart(part, index) {
        order.parts[index] = part;
        setOrder({...order});
    }

    function onClickFinalize() {
        setState({ busy: true, action: "finalize", data: {}});
    }

    if(state.action === 'init') {
        return null;
    }

    function onDeletePart(index) {
        order.parts.splice(index, 1);
        setOrder({...order});
    }

    function onChangeInvoiceNumber(e) {
        order.invoiceNumber = e.target.value;
        setOrder({...order});
    }

    function onClickInvoice(e) {
        setUploadingInvoice(true);
    }

    function onUploadInvoiceOkay(args) {
        setUploadingInvoice(false);
        // Need to add the file to the project as a temporary file:
        if((args !== null) && (args.length > 0 )) {
            setState({busy: true, action: 'upload_invoice', data: args});
        }
    }

    function onUploadInvoiceCancel() {
        setUploadingInvoice(false);
    }

    // Display the invoice details if the customer uploaded one:
    const InvoiceSpecifics = (props) => {


        if(props.invoiceFilename !== null && props.invoiceFilename.length > 0) {
            const normalizedPath = 'http://' + window.location.hostname + ':3030' + props.invoiceFile;
            return (
                <div>
                    <span><a href={normalizedPath} download>{props.invoiceFilename}</a></span>
                </div>
            )
        }
        return null;
    }

    return (
        <div className={"min-h-screen bg-gray-50"}>
            <div className={"container sm:w-4/5 mx-auto pt-24"}>
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Additive Printing - Online Quote
                    </h2>
                    <p className="mt-2 text-center text-xl text-gray-600">
                        Upload STL/OBJ/3MF files here to receive an immediate estimate.
                    </p>
                </div>
                <div className={"w-full mt-4 mb-4"}>
                    <div className={"flex w-full"}>
                        <div className={"items-left"}>
                            <label htmlFor="invoiceNumber" className={"block text-sm font-medium text-gray-700"}>
                                Custom PO #:
                            </label>
                            <input id={"invoiceNumber"} type={"text"} value={order.invoiceNumber} onChange={onChangeInvoiceNumber} size='50' className={"mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                        </div>
                        <div className={"flex ml-4 content-end items-end"}>
                            <button className={"estimateButton h-10"}  onClick={onClickInvoice} style={{ marginRight: '5px'}}>
                                Upload purchase order
                            </button>
                        </div>
                    </div>
                    <div>
                        <InvoiceSpecifics invoiceFile={order.invoiceFile} invoiceFilename={order.invoiceFilename}/>
                    </div>
                </div>
                <div className={"w-full"}>
                    {
                        order.parts.map((item, index) => {
                            return(
                                <Part key={item.path} index={index} pricing={pricing} materials={materials} value={item} onDelete={()=>{ onDeletePart(index)}} onChange={(v) => { onChangePart(v, index)}}/>
                            );
                        })
                    }
                </div>
                <div className={"w-full border border-dashed border-gray-600 bg-indigo-50 h-16 mt-4 mb-4"}>
                    <Dropzone  onDrop={onFilesDropped} accept=".stl,.3mf,.obj">
                        {({getRootProps, getInputProps}) => (
                            <section style={{ height: "100%"}}>
                                <div style={{ height: "100%"}} className={"flex items-center justify-center"} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Drag and drop STL/OBJ files here, or click to select files</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                <div className={"flex items-center justify-center"}>
                    <button disabled={(order.parts.length > 0) ? false : true  } className={"estimateButton"} onClick={onClickFinalize} style={{ marginRight: '5px'}}>
                        Place Order
                    </button>
                </div>
            </div>
            <ApxBusy show={uploading} percentage={progress} message={'Uploading file(s)'}/>
            <ApxUpload show={uploadingInvoice} onClickedOkay={onUploadInvoiceOkay} onClickedCancel={onUploadInvoiceCancel}/>
        </div>
    )
}

const Part = (props) => {

    const { config } = useContext(AppContext);

    const apxReference = useRef();

    const [units] = useState([
        { text: "mm", value: "UNITS_MM"},
        { text: "in", value: "UNITS_IN"}
    ])

    const [finishes] = useState([
        { text: "MJF Finish",       value: "MJF_FINISH"},
        { text: "Vapor Smooth",  value: "VAPOR_SMOOTHING"},
        { text: "Black Dye",        value: "BLACK_DYE"},
        { text: "Vapor Smooth / Black Dye",  value: "AMT_BLACK_DYE"}
    ])

    const [selectedUnits, setSelectedUnits]                     = useState(props.value.units);
    const [selectedFinish, setSelectedFinish]                   = useState(props.value.finish);
    const [selectedQuantity, setSelectedQuantity]               = useState(props.value.quantity);
    const [selectedMaterial, setSelectedMaterial]               = useState(props.value.material);
    const [componentPrice, setComponentPrice]                   = useState(props.value.price);
    const [componentVolume, setComponentVolume]                 = useState(props.value.volume);
    const [generatedVolume, setGeneratedVolume]                 = useState(0);
    const [selectedFinishDisabled, setSelectedFinishDisabled]   = useState(true);
    const [comments, setComments]                               = useState(props.value.comments);

    // REC: Retain a reference to the viewer component, because it
    // is a third-party control that doesn't have a React wrapper:
    const viewer = useRef();

    // REC: Create a permanent viewer instance for displaying the
    // rendered model of the .STL file uploaded for the part:
    useEffect(() => {
        // REC: The path to the .stl file on the web server.
        const initialPath = config.api.base + props.value.path;
        // REC: The initial unit of measure for determining volume.
        const initialUnits = (selectedUnits === 'UNITS_MM') ? 'mm' : 'inch';
        // REC: Need to block out finish if the material type is polypro:
        if(selectedMaterial === "0d96b14d-99d1-42cb-a767-3f10a8d61969") {
            setSelectedFinish("MJF Finish")
        }
    }, [])

    useEffect(()=>{
        if(selectedMaterial === "0d96b14d-99d1-42cb-a767-3f10a8d61969") {
            setSelectedFinish("MJF_FINISH")
            setSelectedFinishDisabled(true);
        } else {
            setSelectedFinishDisabled(false);
        }
    }, [selectedMaterial])

    // REC: Any time the price changes, we notify the parent
    // component that the part specification has been changed:
    useEffect(() => {
        let update = {...props.value}

        update.units = selectedUnits;
        update.volume = componentVolume;
        update.material = selectedMaterial;
        update.finish = selectedFinish;
        update.quantity = selectedQuantity;
        update.price = componentPrice;
        update.comments = comments;
        props.onChange(update);


    }, [componentPrice, componentVolume])

    useEffect(() => {
        switch(selectedUnits) {
            case "UNITS_MM" :
                setComponentVolume(generatedVolume);
                break;
            case "UNITS_IN" :
                setComponentVolume(generatedVolume / 16387)
                break;
            default:
                console.log("ERROR: selected_units value is invalid: " + selectedUnits);
        }
    }, [selectedUnits])

    // REC: If the component's volume changes, we need to adjust
    // some parameters on the quote:
    useEffect(() => {
        console.log("component_volume changed to: " + componentVolume)
        updatePricing();
    }, [componentVolume])

    // This is only called once, when the model is loaded and its
    // volume (in baseline units) is returned. After that we just
    // use this value for switching between mm and inches.
    useEffect(()=>{
        console.log("generated_volume was changed: ")
        setComponentVolume((selectedUnits === "UNITS_MM") ? generatedVolume / 1 : generatedVolume / 16387)
    }, [generatedVolume])

    useEffect(()=>{
        updatePricing();
    },[selectedQuantity, selectedFinish, selectedMaterial, selectedUnits])

    function updatePricing() {
        // Find the selected material's id in the price
        // table and use that table for recalculating:
        let relevantOffsets;
        for(let x=0;x!=props.pricing.length;x++) {
            if (props.pricing[x].material === selectedMaterial) {
                relevantOffsets = props.pricing[x].offsets;
                console.log("Selected material: " + selectedMaterial)
                break;
            }
        }

        let totalVolume = selectedQuantity * componentVolume;
        // REC: The volume isn't allowed to be below 1:
        if(componentVolume < 1 ) {
            totalVolume = selectedQuantity;
        }

        let grayPartsCost = 0;
        let priceByVolume = 0;

        for(let index=0;index!=relevantOffsets.length;index++) {
            if(totalVolume >= relevantOffsets[index].max) {
                grayPartsCost = totalVolume * relevantOffsets[index].gray;
                break;
            }
        }

        let perPartPrice = grayPartsCost / selectedQuantity;
        // Adjust the price based on the finish type:
        if((selectedMaterial != "0d96b14d-99d1-42cb-a767-3f10a8d61969") && (selectedFinish != "MJF_FINISH")) {
            switch(selectedFinish) {
                case "VAPOR_SMOOTHING":
                    console.log("Adjust for vapor smoothing:");
                    perPartPrice = perPartPrice + (perPartPrice * .20);
                    break;
                case "BLACK_DYE":
                    console.log("Adjust for black dye:")
                    perPartPrice = perPartPrice + (perPartPrice * .10);
                    break;
                case "AMT_BLACK_DYE" :
                    perPartPrice = perPartPrice + (perPartPrice * .20);
                    break;
            }
        }

        console.log("PER PART PRICE IS NOW: " + perPartPrice);
        setComponentPrice(perPartPrice);
    }

    function onChangeUnits(e) {
        setSelectedUnits(e.target.value);
        console.log("CHANGED UNITS TO: " + e.target.value);
    }

    function onChangeMaterial(e) {
        setSelectedMaterial(e.target.value);
    }

    function onChangeFinish(e) {
        setSelectedFinish(e.target.value);
    }

    function onChangeQuantity(e) {
        setSelectedQuantity(e.target.value);
    }

    function onChangeComments(e) {
        let update = {...props.value}
        update.comments = e.target.value;
        props.onChange(update);
    }

    function on_model_loaded(info) {
        console.log("ON MODEL LOADED: " + info);
        setGeneratedVolume(info.toFixed(2));
        // Now that the model is loaded, we need to get the element and generate a data
        // URL from the contents of that div:
        //console.log(apxReference.current);
        //var dataURL = apxReference.current.toDataURL('image/png');
        //var image   = dataURL.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
        //console.log({dataURL});
    }

    const totalPrice = (componentPrice * selectedQuantity).toFixed(2);

    return(
        <div className={"w-full border border-solid border-black"}>
            <div className={"flex"}>
                <div style={{ width: "256px", height: "256px"}} className="mr-2">
                    <Apx3D filename={config.api.base + props.value.path} on_model_loaded={on_model_loaded}/>
                </div>
                <div className={"flex flex-col flex-grow space-y-2"}>
                <div className={"flex flex-grow items-center space-x-4 justify-center"}>
                    <div>
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                            Units
                        </label>
                        <select id="units" name="units" value={selectedUnits} onChange={onChangeUnits} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            { units.map((item, index) => {
                                return (
                                    <option value={item.value} key={index}>{item.text}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="material" className="block text-sm font-medium text-gray-700">
                            Material
                        </label>
                        <select id="material" name="material" value={selectedMaterial} onChange={onChangeMaterial} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            { props.materials.map((item, index) => {
                                return (
                                    <option value={item.id} key={index}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="finish" className="block text-sm font-medium text-gray-700">
                            Finish
                        </label>
                        <select id="finish" disabled={selectedFinishDisabled} name="finish" value={selectedFinish} onChange={onChangeFinish} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            { finishes.map((item, index) => {
                                return (
                                    <option value={item.value} key={index}>{item.text}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="quantity" className={"block text-sm font-medium text-gray-700"}>
                            Quantity
                        </label>
                        <input id={"quantity"} type={"number"} value={selectedQuantity} onChange={onChangeQuantity} className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div>
                        <label htmlFor={'partcost'} className={"block text-sm font-medium text-gray-700"}>
                            Cost per part
                        </label>
                        <input id={"partcost"} type={"text"} readOnly={true} value={"$" + componentPrice.toFixed(2)} className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div className={"pr-16"}>
                        <label htmlFor="total price" className={"block text-sm font-medium text-gray-700"}>
                            Price
                        </label>
                        <input id={"price"} type={"text"} readOnly={true} value={"$" + totalPrice} className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div className={"flex justify-end flex-grow justify-end pt-2 h-full color-indigo-500"}>
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={props.onDelete} className="h-6 w-6 stroke-current text-gray-500 hover:text-indigo-500" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </div>
                </div>
                <div className={"pb-4 pr-12"}>
                    <label className="block">
                        <span className="text-gray-700">Special instructions / comments:</span>
                        <textarea className="form-textarea border border-dashed mt-1 block w-full" rows="3" value={comments} onChange={onChangeComments}></textarea>
                    </label>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Appraiser2;
