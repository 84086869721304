import React, { useState } from 'react';
import Dropzone from "react-dropzone";

const ApxUpload = (props) => {

    function onFilesDropped(files) {
        props.onClickedOkay(files);
    }

    function onClickCancel() {
        props.onClickedCancel();
    }

    return(
        <div className={"fixed z-10 inset-0 overflow-y-auto " + ((props.show === true) ? "visible" : "hidden")}>
            <div className={"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"}>
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className={"inline-block align-center bg-white p-8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className={"flex w-full justify-center"}>
                        { props.message }
                    </div>
                    <div className={"w-full border border-dashed border-gray-600 bg-indigo-50 h-16 mt-4 mb-4"}>
                        <Dropzone  onDrop={onFilesDropped} multiple={false} accept=".pdf">
                            {({getRootProps, getInputProps}) => (
                                <section style={{ height: "100%"}}>
                                    <div style={{ height: "100%"}} className={"flex items-center justify-center"} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Drag and drop PO file here, or click to select a file...</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    <div className={'w-full flex justify-end'}>
                        <button type="button" onClick={onClickCancel} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApxUpload;
