import React, {useEffect, useState, useRef} from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js'
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ApxLoader } from './ApxLoader.js';

const Apx3D = (props) => {

    // The div that contains the three.js scene:
    const scene_container = useRef();

    const [mode, set_mode] = useState(props.filename.split('.').pop().toLowerCase());
    const [scene, set_scene] = useState(new THREE.Scene());
    const [camera, set_camera] = useState(new THREE.PerspectiveCamera(40, 1, 1, 5000));
    const [renderer, set_renderer] = useState(new THREE.WebGLRenderer({
        antialias: true, alpha: true, outputEncoding: THREE.LinearEncoding, preserveDrawingBuffer: true}));

    const [snapshot, set_snapshot] = useState(false)

    useEffect(() => {

        camera.position.x = 0;
        camera.position.y = 0;
        camera.position.z = 0;
        camera.lookAt(new THREE.Vector3(0,0,0));

        var light = new THREE.HemisphereLight(0xffffff, 0x000000, 1);
        scene.add(light);

        //const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true});
        renderer.setClearColor(0x000000, 0x00)

        renderer.setSize(scene_container.current.clientWidth, scene_container.current.clientHeight);

        // Append the renderer to the parent container:
        scene_container.current.appendChild(renderer.domElement);

        // Retrieve the extension of the file so that we know which loader to
        // use for importing it into the scene:
        var scene_loader;
        const extension = props.filename.split('.').pop();

        switch(mode) {
            case 'stl':
                scene_loader = new STLLoader();
                break;
            case '3mf':
                scene_loader = new ThreeMFLoader();
                break;
            default:
                break;
        }

        scene_loader.load(props.filename, on_load, on_progress, on_error);

    }, [])

    useEffect(()=>{
        //if(snapshot === true) {
        //    const image_data = renderer.domElement.toDataURL();
        //    console.log(image_data);
        //}
        //set_snapshot(false);
    }, [snapshot])

    function on_load(content) {

        var model_volume = 0;

        const default_material_params = {
            color: 0x202020,
            specular: 0x009900,
            shininess: 60,
            flatShading: THREE.FlatShading,
        }

        const default_material = new THREE.MeshPhongMaterial(default_material_params);
        if(mode === "stl") {
            content = new THREE.Mesh(content, default_material);
            model_volume = get_volume(content.geometry);
        }

        const box = new THREE.Box3().setFromObject( content );
        const center = new THREE.Vector3();
        box.getCenter( center );
        content.position.sub( center ); // center the model

        if(mode === "3mf") {
            console.log("3MF CONTENT:")
            console.log(content);

            console.log("CALCULATE VOLUME FOR 3MF FILE:")
            content.traverse(item => {
                console.log(item);
                if (item.isMesh) {
                    model_volume += get_volume(item.geometry)
                    console.log("MODEL VOLUME: " + model_volume)
                }
            });
        }

        const controls = new OrbitControls(camera, renderer.domElement)

        var animate = function () {
            requestAnimationFrame(animate)
            controls.update()
            renderer.render(scene, camera)
        };

        scene.add(content);
        fitCameraToCenteredObject(camera, content, 1.25);
        renderer.render( scene, camera );
        animate();

        props.on_model_loaded(model_volume);
    }

    function on_error(args) {
        console.log("ON ERROR:")
        console.log(args);
    }

    function on_progress(args) {

    }

    function get_volume(geometry) {
        // 16387 = cubic mms in an inch

        if (!geometry.isBufferGeometry) {
            console.log("'geometry' must be an indexed or non-indexed buffer geometry");
            return 0;
        }
        var isIndexed = geometry.index !== null;
        let position = geometry.attributes.position;
        let sum = 0;
        let p1 = new THREE.Vector3(),
            p2 = new THREE.Vector3(),
            p3 = new THREE.Vector3();
        if (!isIndexed) {
            let faces = position.count / 3;
            for (let i = 0; i < faces; i++) {
                p1.fromBufferAttribute(position, i * 3 + 0);
                p2.fromBufferAttribute(position, i * 3 + 1);
                p3.fromBufferAttribute(position, i * 3 + 2);
                sum += signedVolumeOfTriangle(p1, p2, p3);
            }
        }
        else {
            let index = geometry.index;
            let faces = index.count / 3;
            for (let i = 0; i < faces; i++){
                p1.fromBufferAttribute(position, index.array[i * 3 + 0]);
                p2.fromBufferAttribute(position, index.array[i * 3 + 1]);
                p3.fromBufferAttribute(position, index.array[i * 3 + 2]);
                sum += signedVolumeOfTriangle(p1, p2, p3);
            }
        }
        return sum;
    }

    function signedVolumeOfTriangle(p1, p2, p3) {
        return (p1.dot(p2.cross(p3)) / 6.0);
    }

    return(
        <div ref={scene_container} style={{ height: "100%"}} />
    )

}

const fitCameraToCenteredObject = function (camera, object, offset ) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject( object );
    console.log("BOUNDING BOX");
    console.log(boundingBox)

    var middle = new THREE.Vector3();
    var size = new THREE.Vector3();
    boundingBox.getSize(size);

    // figure out how to fit the box in the view:
    // 1. figure out horizontal FOV (on non-1.0 aspects)
    // 2. figure out distance from the object in X and Y planes
    // 3. select the max distance (to fit both sides in)
    //
    // The reason is as follows:
    //
    // Imagine a bounding box (BB) is centered at (0,0,0).
    // Camera has vertical FOV (camera.fov) and horizontal FOV
    // (camera.fov scaled by aspect, see fovh below)
    //
    // Therefore if you want to put the entire object into the field of view,
    // you have to compute the distance as: z/2 (half of Z size of the BB
    // protruding towards us) plus for both X and Y size of BB you have to
    // figure out the distance created by the appropriate FOV.
    //
    // The FOV is always a triangle:
    //
    //  (size/2)
    // +--------+
    // |       /
    // |      /
    // |     /
    // | F° /
    // |   /
    // |  /
    // | /
    // |/
    //
    // F° is half of respective FOV, so to compute the distance (the length
    // of the straight line) one has to: `size/2 / Math.tan(F)`.
    //
    // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
    // the camera.fov is the vertical FOV.

    const fov = camera.fov * ( Math.PI / 180 );
    const fovh = 2*Math.atan(Math.tan(fov/2) * camera.aspect);
    let dx = size.z / 2 + Math.abs( size.x / 2 / Math.tan( fovh / 2 ) );
    let dy = size.z / 2 + Math.abs( size.y / 2 / Math.tan( fov / 2 ) );
    let cameraZ = Math.max(dx, dy);

    // offset the camera, if desired (to avoid filling the whole canvas)
    if( offset !== undefined && offset !== 0 ) cameraZ *= offset;

    camera.position.set( 0, 0, cameraZ );

    // set the far plane of the camera so that it easily encompasses the whole object
    const minZ = boundingBox.min.z;
    const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.updateProjectionMatrix();
};

export default Apx3D;

