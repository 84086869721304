import React from 'react';

const ApxDialog = (props) => {

    function onClickOkay() {
        props.onOkay();
    }

    return(
        <div className={"fixed z-10 inset-0 overflow-y-auto " + ((props.show === true) ? "visible" : "hidden")}>
            <div className={"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"}>
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className={"inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className={"flex w-full justify-center pt-8 pb-6 px-3"}>
                    { props.message }
                    </div>
                    <div className="bg-gray-100 px-3 pb-3 pt-2 sm:px-4 sm:flex sm:flex-row-reverse">
                        <button type="button" onClick={onClickOkay} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Okay
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ApxDialog;
