import React, {useState} from 'react';
import {Redirect} from "react-router-dom";

const Submitted = (props) => {

    const [redirect, set_redirect] = useState({})

    function on_clicked_exit(e) {
        set_redirect({ path: "/dashboard/orders"})
        //return <Redirect to={state.context.redirect}/>
    }

    if(redirect.path) {
        return <Redirect to={redirect.path}/>
    }

    return (
        <div className="min-h-screen flex justify-center bg-gray-50 pt-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Your order has been submitted for processing.
                    </h2>
                    <p className="mt-4 text-center text-xl text-gray-600">
                        You will receive an order confirmation email shortly.<br/>
                        If you have not received any correspondence from us, please check your spam or junk mail folders. Else, please reach out to us at <a href="mailto:jobtravelers@abcorp.com">jobtravelers@abcorp.com</a>.
                    </p>
                    <div className={"flex items-center justify-center w-full"}>
                        <button className={"uploadButton mt-8 item-center justify-center"} onClick={on_clicked_exit}>
                            Okay
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Submitted;
