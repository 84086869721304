import React, {useContext, useState, useRef, useEffect} from 'react';
import Dashview from 'paths/admin/dashboard/Dashview';
import axios from 'axios';
import {AppContext} from 'AppContext';
import {Redirect, useHistory} from "react-router-dom";
import { NavLink } from 'react-router-dom'
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import * as THREE from 'three';
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Apx3D from 'comps/Apx3D.js';

const Content = (props) => {
    // Retrieve user idenntifiy and system configuration
    // from the global application context:
    const { identity, config } = useContext(AppContext);

    const sceneReference = useRef();

    useEffect(()=>{

        const scene = new THREE.Scene();
        //scene.background = new THREE.Color( 0x000000 );
        //scene.fog = new THREE.Fog( 0xa0a0a0, 10, 500 );

        const scene_camera = new THREE.PerspectiveCamera(40, 1, 1, 5000);
        scene_camera.position.x = 0;
        scene_camera.position.y = 0;
        scene_camera.position.z = 0;
        scene_camera.lookAt(new THREE.Vector3(0,0,0));

        const helper = new THREE.CameraHelper( scene_camera );
        scene.add( helper );

        //const scene_lighting = new THREE.AmbientLight(0xa0a0a0, 1);
        //scene.add(scene_lighting);


        var light = new THREE.HemisphereLight(0xffffff, 0x202020, 1);
        scene.add(light);

        //const directional_light = new THREE.DirectionalLight(0xff0000, 100)
        //directional_light.position.set(0,10, 10);
        //scene.add(directional_light);

        //const scene_renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true});
        const scene_renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true});
        scene_renderer.setClearColor(0x000000, .25)
        scene_renderer.setSize(256, 256);

        sceneReference.current.appendChild(scene_renderer.domElement);

        const theLoader = new ThreeMFLoader();


        const phong = new THREE.MeshPhongMaterial( { color: 0xa0a0a0, specular: 0x009900, shininess: 30, flatShading: THREE.FlatShading }   );
        theLoader.load( '/test2.3mf', function ( content ) {

            var box = new THREE.Box3().setFromObject( content );
            var center = new THREE.Vector3();
            box.getCenter( center );
            content.position.sub( center ); // center the model

           content.traverse(object => {
               if(object.isMesh) {
                   object.material = phong;
               }
           });

            const controls = new OrbitControls(scene_camera, scene_renderer.domElement)
            var animate = function () {
                requestAnimationFrame(animate)
                controls.update()
                scene_renderer.render(scene, scene_camera)
            };

            //const model = content.scene.children[0];
            //model.scale.set(0.5,0.5,0.5);
            scene.add(content);
            fitCameraToCenteredObject(scene_camera, content, 0);

            //scene_renderer.render( scene, scene_camera );
            //animate();

        }, undefined, function ( error ) {
            console.error( error );
        } );

    }, [])


    return(
        <div className={"container border-2 border-solid sm:w-4/5 mx-auto"}>
            <div ref={sceneReference} className={"border-4 border-black"} style={{ width: '256px', height: '256px'}}/>
            <div style={{ width: "256px", height: "256px"}}>
                <Apx3D filename={'/test2.3mf'}/>
            </div>
        </div>
    )

}

function get_mesh_stats(geo, factor)
{
    var x1,x2,x3,y1,y2,y3,z1,z2,z3,i;
    var len=geo.faces.length;
    var totalVolume=0;
    var totalArea=0;
    var a,b,c,s;

    for (i=0;i<len;i++)
    {
        x1=geo.vertices[geo.faces[i].a].x*factor;
        y1=geo.vertices[geo.faces[i].a].y*factor;
        z1=geo.vertices[geo.faces[i].a].z*factor;
        x2=geo.vertices[geo.faces[i].b].x*factor;
        y2=geo.vertices[geo.faces[i].b].y*factor;
        z2=geo.vertices[geo.faces[i].b].z*factor;
        x3=geo.vertices[geo.faces[i].c].x*factor;
        y3=geo.vertices[geo.faces[i].c].y*factor;
        z3=geo.vertices[geo.faces[i].c].z*factor;

        totalVolume +=
            (-x3 * y2 * z1 +
                x2 * y3 * z1 +
                x3 * y1 * z2 -
                x1 * y3 * z2 -
                x2 * y1 * z3 +
                x1 * y2 * z3);

        a=geo.vertices[geo.faces[i].a].distanceTo(geo.vertices[geo.faces[i].b])*factor;
        b=geo.vertices[geo.faces[i].b].distanceTo(geo.vertices[geo.faces[i].c])*factor;
        c=geo.vertices[geo.faces[i].c].distanceTo(geo.vertices[geo.faces[i].a])*factor;
        s=(a+b+c)/2;
        totalArea+=Math.sqrt(s*(s-a)*(s-b)*(s-c));
    }

    return [Math.abs(totalVolume/6), totalArea, geo.faces.length];
}

const fitCameraToCenteredObject = function (camera, object, offset ) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject( object );
    console.log("BOUNDING BOX");
    console.log(boundingBox)

    var middle = new THREE.Vector3();
    var size = new THREE.Vector3();
    boundingBox.getSize(size);

    // figure out how to fit the box in the view:
    // 1. figure out horizontal FOV (on non-1.0 aspects)
    // 2. figure out distance from the object in X and Y planes
    // 3. select the max distance (to fit both sides in)
    //
    // The reason is as follows:
    //
    // Imagine a bounding box (BB) is centered at (0,0,0).
    // Camera has vertical FOV (camera.fov) and horizontal FOV
    // (camera.fov scaled by aspect, see fovh below)
    //
    // Therefore if you want to put the entire object into the field of view,
    // you have to compute the distance as: z/2 (half of Z size of the BB
    // protruding towards us) plus for both X and Y size of BB you have to
    // figure out the distance created by the appropriate FOV.
    //
    // The FOV is always a triangle:
    //
    //  (size/2)
    // +--------+
    // |       /
    // |      /
    // |     /
    // | F° /
    // |   /
    // |  /
    // | /
    // |/
    //
    // F° is half of respective FOV, so to compute the distance (the length
    // of the straight line) one has to: `size/2 / Math.tan(F)`.
    //
    // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
    // the camera.fov is the vertical FOV.

    const fov = camera.fov * ( Math.PI / 180 );
    const fovh = 2*Math.atan(Math.tan(fov/2) * camera.aspect);
    let dx = size.z / 2 + Math.abs( size.x / 2 / Math.tan( fovh / 2 ) );
    let dy = size.z / 2 + Math.abs( size.y / 2 / Math.tan( fov / 2 ) );
    let cameraZ = Math.max(dx, dy);

    // offset the camera, if desired (to avoid filling the whole canvas)
    if( offset !== undefined && offset !== 0 ) cameraZ *= offset;

    camera.position.set( 0, 0, cameraZ );

    // set the far plane of the camera so that it easily encompasses the whole object
    const minZ = boundingBox.min.z;
    const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.updateProjectionMatrix();
};

const Sandbox = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Sandbox;