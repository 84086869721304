import React, {useState} from 'react';
import {Redirect} from "react-router-dom";

const ResetPassword4 = (props) => {

    const [redirect, set_redirect] = useState({})

    function on_clicked_exit(e) {
        set_redirect({ path: "/sign-in"})
    }

    if(redirect.path) {
        return <Redirect to={redirect.path}/>
    }

    return (
        <div className="min-h-screen flex justify-center bg-gray-50 pt-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Your password has been reset.
                    </h2>
                    <p className="mt-4 text-center text-xl text-gray-600">
                        You can now login with your new password.
                    </p>
                    <div className={"flex items-center justify-center w-full"}>
                        <button className={"uploadButton mt-8 item-center justify-center"} onClick={on_clicked_exit}>
                            Okay
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword4;