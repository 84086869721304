import React, { useContext, useState, useEffect, useRef } from 'react';
import Dashview from 'paths/admin/dashboard/Dashview';
import axios from 'axios';
import { AppContext } from 'AppContext';
import { Redirect } from "react-router-dom";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Toolbar } from '@syncfusion/ej2-react-grids';

const Content = (props) => {
    const { identity, config, setIdentity } = useContext(AppContext);

    const [state, setState] = useState({ id: "READY"});
    const [accounts, setAccounts] = useState([]);
    const [redirect, setRedirect] = useState(null)

    const frameReference = useRef();
    const gridReference = useRef();

    useEffect(() => {

        const action = async () => {
            const rxAccounts = await axios.get(config.api.base + '/admin/accounts');
            console.log(rxAccounts.data);
            setAccounts(rxAccounts.data);
        }

        action();
        gridReference.current.height = window.innerHeight - (frameReference.current.offsetTop + 100);

    }, [])

    useEffect( () => {
        switch(state.id) {
            case "READY":
                break;
            case "ACCOUNT_EDIT":
                setRedirect("/admin/dashboard/users/" + state.data.acct_id);
                break;
            case "ACCOUNT_DELETE" :
                onEnterStateAccountDelete(state.data);
                break;
            case "ACCOUNT_IMPERSONATE" :
                onEnterStateAccountImpersonate(state.data);
                break;
        }


    }, [state])

    const toolbarOptions = [
        { text: 'Open', prefixIcon: 'e-edit',  align: 'Right', id: 'account_edit'},
        { text: 'Delete', align: 'Right', id: 'account_delete'},
        { text: 'Impersonate', align: 'Right', id: 'account_impersonate'}
    ];

    function onEnterStateAccountDelete(args) {
        const action = async () => {
            try {
                await axios.delete(config.api.base + '/admin/accounts/' + args.acct_id);
                const accounts = await axios.get(config.api.base + '/admin/accounts');
                setAccounts(accounts.data);

            } catch(x) {

            }
        }
        action();
    }

    function onEnterStateAccountImpersonate(args) {
        const action = async() => {
            try {
                const response = await axios.get(config.api.base + '/ident/' + args.user_id);
                console.log(response);
                setIdentity(response);
                setRedirect("/dashboard/orders");
            } catch(x) {
                console.log(x);
            }
        }
        action();
    }

    function onToolbarClicked(args) {

        if(args.item.id === "account_edit") {
            // Get the selected account from the grid control:
            const selectedIndexes = gridReference.current.getSelectedRowIndexes();
            setState({ id: "ACCOUNT_EDIT", data: { ...accounts[selectedIndexes[0]]}});
        }

        if(args.item.id === "account_delete") {
            const selectedIndexes = gridReference.current.getSelectedRowIndexes();
            setState({ id: "ACCOUNT_DELETE", data: { ...accounts[selectedIndexes[0]]}});
        }

        if(args.item.id === "account_impersonate") {
            const selectedIndexes = gridReference.current.getSelectedRowIndexes();
            setState({ id: "ACCOUNT_IMPERSONATE", data: { ...accounts[selectedIndexes[0]]}});
        }
    }

    if(redirect != null) {
        return <Redirect push to={{ pathname: redirect, state: {  } }}/>
    }

    return(
        <div ref={frameReference} className={"container border-2 border-solid select-none sm:w-4/5 mx-auto"} style={{ WebkitUserSelect: "none" }}> 
            <GridComponent ref={gridReference} dataSource={accounts} toolbar={toolbarOptions} toolbarClick={onToolbarClicked}>
                <ColumnsDirective>
                    <ColumnDirective field='acct_id' headerText={"Account Id"}/>
                    <ColumnDirective field='first_name' width="14%" headerText={"First Name"} />
                    <ColumnDirective field='last_name' width="14%" headerText={"Last Name"}/>
                    <ColumnDirective field='company' headerText={"Company"}/>
                    <ColumnDirective field='email' headerText={"Email"}/>
                    <ColumnDirective field='pricing_table' headerText={"Pricing Table"}/>
                </ColumnsDirective>
                <Inject services={[ Toolbar ]} />
            </GridComponent>
        </div>
    )

}

const Users = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Users;