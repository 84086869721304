import React, {useEffect, useState, useContext, useRef} from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js'
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ApxLoader } from './ApxLoader';
// eslint-disable-next-line import/no-webpack-loader-syntax
import {AppContext} from "../AppContext";

// Instantiate a global instance of a renderer that will be
// used to render each ApxScene instance:
const canvas = document.createElement('canvas');
const renderer = new THREE.WebGLRenderer({canvas, alpha: true});
renderer.setScissorTest(true);
// Create a global map that is used to keep track of all the
// scenes that need to be rendered:
const scene_map = new Map();

// Global animation loop that renders all of the scenes that
// have been added to the global scene_map:
let counter = 0;
function animate() {
    try {
        for (const [key, context] of scene_map) {
            // get the viewport relative position of this element
            const rect = context.scene_reference.current.getBoundingClientRect();
            const {left, right, top, bottom, width, height} = rect;
            const rendererCanvas = renderer.domElement;


            const offscreen = bottom < 0 || top > window.innerHeight || right < 0 || left > window.innerWidth;

            if (!offscreen) {
                // make sure the renderer's canvas is big enough
                if (rendererCanvas.width < width || rendererCanvas.height < height) {
                    renderer.setSize(width, height, false);
                }

                // make sure the canvas for this area is the same size as the area
                if (context.scene_context.canvas.width !== width || context.scene_context.canvas.height !== height) {
                    context.scene_context.canvas.width = width;
                    context.scene_context.canvas.height = height;
                }

                renderer.setScissor(0, 0, width, height);
                renderer.setViewport(0, 0, width, height);

                context.camera.aspect = rect.width / rect.height;
                context.camera.updateProjectionMatrix();
                //context.controls.handleResize();
                context.controls.update();
                renderer.render(context.scene, context.camera);

                // copy the rendered scene to this element's canvas
                context.scene_context.globalCompositeOperation = 'copy';
                context.scene_context.drawImage(
                    rendererCanvas,
                    0, rendererCanvas.height - height, width, height,  // src rect
                    0, 0, width, height);                              // dst rect
            }
        }
    } catch(x) {
        // Terminate the loop if an exception is thrown. This is the easy
        // way to shutdown the loop if a component no longer needs it:
        scene_map.clear();
        return;
    }

    requestAnimationFrame(animate);
}

function start_global_animation_loop() {
    // Start the animation loop:
    if(scene_map.size == 1) {
        requestAnimationFrame(animate);
    }
}

const ApxScene = (props) => {
    const { identity, config } = useContext(AppContext);

    const scene_reference = useRef();
    const progress_reference = useRef();

    const [context, set_context] = useState({})
    const [progress, set_progress] = useState({ status : "visible", percentage: 0 });

    const [pending_action, set_pending_action] = useState({ id: "INIT", data: {}})

    const [percentage, set_percentage] = useState(0);

    useEffect(()=> {
        switch(pending_action.id) {
            case "INIT":
                on_action_init(pending_action.data);
                break;
            case "ANIMATE":
                on_action_animate(pending_action.data);
                break;
        }
    }, [pending_action])

    function on_action_init(data) {

        let scene_loader;
        const extension = props.filename.split('.').pop();
        switch(extension) {
            case 'stl':
                scene_loader = new STLLoader();
                break;
            case '3mf':
                scene_loader = new ApxLoader();
                break;
            default:
                break;
        }

        console.log("ATTEMPTING TO LOAD SCENE")
        scene_loader.load(props.filename, on_scene_loader_complete, on_scene_loader_progress, on_scene_loader_error);

    }

    function on_action_animate(data) {
        // At this point we just need to register this component
        // with the global scene map so that it can be rendered:
        scene_map.set(props.tag, context);
        start_global_animation_loop();
    }

    function on_scene_loader_complete(content) {
        set_progress({ status : "hidden", percentage: 0 })
        const scene = new THREE.Scene();

        const fov = 45;
        const aspect = 2;
        const near = 0.1;
        const far = 5;
        const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        camera.position.set(0, 1, 2);
        camera.lookAt(0, 0, 0);

        scene.add(camera);

        const color = 0xFFFFFF;
        const intensity = 1;
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.set(-1, 2, 4);

        camera.add(light);

        //const geometry = new THREE.BoxGeometry(1, 1, 1);
        //const material = new THREE.MeshPhongMaterial({color: 'red'});
        //const mesh = new THREE.Mesh(geometry, material);


        const box = new THREE.Box3().setFromObject( content );
        const center = new THREE.Vector3();
        box.getCenter( center );
        content.position.sub( center );

        // Back the camera out, if needed, to ensure the whole scene
        // can be fit into the window:
        fitCameraToCenteredObject(camera, content, 1.25);

        scene.add(content);

        const scene_context = document.createElement('canvas').getContext('2d');
        scene_reference.current.appendChild(scene_context.canvas);

        const controls = new OrbitControls(camera, scene_context.canvas)
        set_context({renderer, scene, camera, scene_reference, scene_context, controls})
        set_pending_action({ id: "ANIMATE", data: {}});
    }

    function on_scene_loader_progress(args) {
        //progress.percentage = 100 * (args.loaded / args.total);
        //set_progress({...progress});
    }

    // progress.percentage = 100 * (args.loaded / args.total)

    function on_scene_loader_error(args) {

    }

    return(
        <div ref={scene_reference} style={{ height: "100%"}}>
            <div className={"flex flex-col w-full justify-center items-center h-full " + progress.status }>
                <img src={"loading.gif"}/>
            </div>
        </div>
    )
}


const fitCameraToCenteredObject = function (camera, object, offset ) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject( object );
    console.log("BOUNDING BOX");
    console.log(boundingBox)

    var middle = new THREE.Vector3();
    var size = new THREE.Vector3();
    boundingBox.getSize(size);

    // figure out how to fit the box in the view:
    // 1. figure out horizontal FOV (on non-1.0 aspects)
    // 2. figure out distance from the object in X and Y planes
    // 3. select the max distance (to fit both sides in)
    //
    // The reason is as follows:
    //
    // Imagine a bounding box (BB) is centered at (0,0,0).
    // Camera has vertical FOV (camera.fov) and horizontal FOV
    // (camera.fov scaled by aspect, see fovh below)
    //
    // Therefore if you want to put the entire object into the field of view,
    // you have to compute the distance as: z/2 (half of Z size of the BB
    // protruding towards us) plus for both X and Y size of BB you have to
    // figure out the distance created by the appropriate FOV.
    //
    // The FOV is always a triangle:
    //
    //  (size/2)
    // +--------+
    // |       /
    // |      /
    // |     /
    // | F° /
    // |   /
    // |  /
    // | /
    // |/
    //
    // F° is half of respective FOV, so to compute the distance (the length
    // of the straight line) one has to: `size/2 / Math.tan(F)`.
    //
    // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
    // the camera.fov is the vertical FOV.

    const fov = camera.fov * ( Math.PI / 180 );
    const fovh = 2*Math.atan(Math.tan(fov/2) * camera.aspect);
    let dx = size.z / 2 + Math.abs( size.x / 2 / Math.tan( fovh / 2 ) );
    let dy = size.z / 2 + Math.abs( size.y / 2 / Math.tan( fov / 2 ) );
    let cameraZ = Math.max(dx, dy);

    // offset the camera, if desired (to avoid filling the whole canvas)
    if( offset !== undefined && offset !== 0 ) cameraZ *= offset;

    camera.position.set( 0, 0, cameraZ );

    // set the far plane of the camera so that it easily encompasses the whole object
    const minZ = boundingBox.min.z;
    const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.updateProjectionMatrix();
};

export default ApxScene;