import React, {useContext, useState, useEffect} from 'react';
import Dashview from 'paths/dashboard/Dashview';
import axios from 'axios';
import {AppContext} from 'AppContext';
import {Redirect, useHistory} from "react-router-dom";
import { NavLink } from 'react-router-dom'

const QuoteItem = (props) => {

    const [redirect, setRedirect] = useState(false);

    var link = "/quotes/" + props.value.id;

    let timestamp = props.value.created_at;
    let formatted = '';
    formatted += (timestamp.getMonth() +1 );
    formatted += '/' + (timestamp.getDay().length < 2 ? '0' + timestamp.getDay() : timestamp.getDay());
    formatted += '/' + timestamp.getFullYear();
    formatted += ' - ';
    formatted += (timestamp.getHours().length < 2 ? '0' + timestamp.getHours() : timestamp.getHours());
    formatted += ':';
    formatted += (timestamp.getMinutes().length < 2 ? '0' + timestamp.getMinutes() : timestamp.getMinutes());

    const bgcolor = props.index % 2 === 0 ? "bg-green-50"   : "bg-green-100";
    const hvcolor = props.index % 2 === 0 ? "bg-green-200"  : "bg-green-300";

    // Determine the material name:
    function getMaterialText(m) {
        console.log("looking up: " + m)
        console.log(props.materials)
        let materialText = "unknown";
        for (let x = 0; x != props.materials.length; x++) {
            if (props.materials[x].id === m) {
                materialText = props.materials[x].name;
            }
        }
        return materialText;
    }

    if(redirect === true) {
        return  <Redirect to={{ pathname: link }} />
    }

    return(
        <div className={"flex mt-4 space-x-4 border-b-2 " + bgcolor + " hover:" + hvcolor } onClick={(e) => setRedirect(true)}>
            <div>
                <img src={'/icon-file-stl.png'} width={'64px'} height={'64px'}></img>
            </div>
            <div className={'flex flex-col'}>
                { props.value.metadata.parts.map((item, index) => {
                    return <div key={index}>{item.file}</div>
                })}
            </div>
            <div className={'flex flex-col'}>
                { props.value.metadata.parts.map((item, index) => {
                    return (
                        <div key={index}>
                            <span>Material: { getMaterialText(item.material) }</span>
                            <span className={"ml-4"}>Quantity: { item.quantity }</span>
                            <span className={"ml-4"}>Price: ${ item.price}</span>
                            <span className={"ml-4"}>Total Cost: ${ item.quantity * item.price }</span>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

const Content = (props) => {
    const { identity, config } = useContext(AppContext);
    const history = useHistory();

    const [quotes, setQuotes] = useState([]);
    const [materials, setMaterials] = useState([]);

    useEffect(()=>{
        // Retrieve all quotes from the server:
        const action = async () => {
            try {
                // Retrieve material data from the server:
                const rxMaterials = await axios.get(config.api.base + '/materials');
                setMaterials(rxMaterials.data);
                const response = await axios.get(config.api.base + '/quotes/');
                setQuotes(response.data);
            } catch(x) {
                console.log(x);
            }

        }
        action();

    }, [])

    return(
        <div className={"container mx-auto"}>
            <div className={"flex w-full border-b-2 border-solid"}>
                <div>
                    <span className={"text-3xl"}>Quotes</span>
                </div>
                <div className={"flex-grow border border-solid"}>
                    <div className={"flex h-full justify-end items-center "}>
                        <div className={"border border-solid"}>
                            <NavLink to={"/quotes/new"}>
                                New Quote
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                { quotes.map((item, index) => {
                    return <QuoteItem value={item} materials={materials} key={index} index={index}/>
                })}
            </div>

        </div>
    )
}

const Quotes = (props) => {



    return (<Dashview {...props}><Content/></Dashview>);
}

export default Quotes;