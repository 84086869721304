import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

// REC: Application routes:
import AppRoute                     from 'routes/AppRoute';
import SecRoute                     from 'routes/SecRoute';
// REC: Application paths:
import SignUp                       from 'paths/auth/SignUp.js';
import SignIn                       from 'paths/auth/SignIn.js';
import Appraiser                    from 'paths/appraise/Appraiser2';
import Submitted                    from 'paths/complete/Submitted';
import SignupComplete               from 'paths/complete/SignUp';
import Approval                     from 'paths/complete/Approval';
import Dashboard                    from 'paths/dashboard/Dashboard';
import DashboardQuotes              from 'paths/dashboard/quotes/Home.js';
import DashboardOrders              from 'paths/dashboard/orders/Orders.js';
import DashboardOrdersEditor        from 'paths/dashboard/orders/Editor.js';
import DashboardAccount             from 'paths/dashboard/account/Account.js';
import AdminDashboard               from 'paths/admin/dashboard/Dashboard.js';
import AdminDashboardUsers          from 'paths/admin/dashboard/users/Users.js';
import AdminDashboardOrders         from 'paths/admin/dashboard/orders/Orders.js';
import AdminDashboardOrdersEditor   from 'paths/admin/dashboard/orders/Editor.js';
import AdminDashboardSandbox        from 'paths/admin/dashboard/sandbox/Sandbox';
import AdminDashboardUserEditor     from 'paths/admin/dashboard/users/Editor.js';
import AdminDashboardPricing        from 'paths/admin/dashboard/pricing/Pricing.js'
import AdminDashboardPricingEditor  from 'paths/admin/dashboard/pricing/Editor.js'
import Testing                      from 'paths/test/Testing';
import Welcome                      from 'paths/auth/Welcome.js';
import ResetPassword1               from 'paths/auth/Reset1.js'
import ResetPassword2               from 'paths/auth/Reset2.js'
import ResetPassword3               from 'paths/auth/Reset3.js';
import ResetPassword4               from 'paths/auth/Reset4.js';


// REC: Configure a response interceptor in the axios instance
// so that we can deserialize date strings into object:
axios.defaults.transformResponse = [(data, headers) => {
  if(headers["content-type"]) {
    const content_type = headers["content-type"].split(';')[0];
    if (content_type === 'application/json') {
      const regex_iso_date = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
      const iso_date_parser = (key, value) => {
        if (typeof value === 'string') {
          var is_iso_date_string = regex_iso_date.exec(value);
          if (is_iso_date_string) {
            return new Date(value);
          }
        }
        return value;
      }
      return (JSON.parse(data, iso_date_parser));
    } else {
      return data;
    }
  } else {
    return data;
  }
}];

const App =  () => {
  return (
      <Router>
        <Switch>
          <AppRoute exact path={'/'}                                component={SignIn}/>
          <AppRoute exact path={'/sign-up'}                         component={SignUp}/>
          <AppRoute exact path={'/sign-in'}                         component={SignIn}/>
          <SecRoute exact path={'/quotes/submitted'}                component={Submitted}/>
          <SecRoute exact path={'/quotes/:id'}                      component={Appraiser}/>
          <AppRoute exact path={'/sign-up/complete'}                component={SignupComplete}/>
          <AppRoute exact path={'/approve/:id'}                     component={Approval}/>
          <SecRoute exact path={"/dashboard"}                       component={Dashboard}/>
          <SecRoute exact path={"/dashboard/quotes"}                component={DashboardQuotes}/>
          <SecRoute exact path={"/dashboard/orders"}                component={DashboardOrders}/>
          <SecRoute exact path={"/dashboard/orders/:id"}            component={DashboardOrdersEditor}/>
          <SecRoute exact path={"/dashboard/account"}               component={DashboardAccount}/>
          <SecRoute exact path={"/admin/dashboard"}                 component={AdminDashboard}/>
          <SecRoute exact path={"/admin/dashboard/users"}           component={AdminDashboardUsers}/>
          <SecRoute exact path={"/admin/dashboard/orders"}          component={AdminDashboardOrders}/>
          <SecRoute exact path={"/admin/dashboard/orders/:id"}       component={AdminDashboardOrdersEditor}/>
          <SecRoute exact path={"/admin/dashboard/users/:id"}       component={AdminDashboardUserEditor}/>
          <SecRoute exact path={"/admin/dashboard/sandbox"}         component={AdminDashboardSandbox}/>
          <SecRoute exact path={"/admin/dashboard/pricing"}         component={AdminDashboardPricing}/>
          <SecRoute exact path={"/admin/dashboard/pricing/:id"}     component={AdminDashboardPricingEditor}/>
          <AppRoute exact path={"/testing"}                         component={Testing}/>
          <AppRoute exact path={"/welcome"}                         component={Welcome}/>
          <AppRoute exact path={"/reset-password"}                  component={ResetPassword1}/>
          <AppRoute exact path={"/reset-password-started"}          component={ResetPassword2}/>
          <AppRoute exact path={"/reset-password-request/:id"}      component={ResetPassword3}/>
          <AppRoute exact path={"/reset-password-complete"}         component={ResetPassword4}/>
        </Switch>
      </Router>
  )
}

export default App;
