import React from 'react';

const Submitted = (props) => {

    return (
        <div className="min-h-screen flex justify-center bg-gray-50 pt-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Thank you for signing up for access to ABCorp's 3D printing portal.
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        A member of our additive printing solutions team will contact you
                        shortly to enable your account.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Submitted;