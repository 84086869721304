import React, { useState } from 'react';

const NewPriceTableDialog = (props) => {

    const [selected_price_table, set_selected_price_table] = useState("");
    const [price_table_name, set_price_table_name] = useState("");

    function onClickOkay() {
        props.onOkay({ price_table_name, selected_price_table});
    }

    function onClickCancel() {
        props.onCancel();
    }

    function on_change_selected_price_table(args) {
        set_selected_price_table(args.target.value);
    }

    function on_change_price_table_name(args) {
        set_price_table_name(args.target.value);
    }

    return(
        <div className={"fixed z-10 inset-0 overflow-y-auto " + ((props.show === true) ? "visible" : "hidden")}>
            <div className={"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"}>
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className={"inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className={"flex w-full font-medium text-gray-700 border-bottom border-2 justify-start pl-2 pt-2"}>
                        Create a new price table...
                    </div>
                    <div className={"p-8"}>
                        <div>
                            <label htmlFor="last-name">Last name</label>
                            <input id="last-name" name="lastName" type="text"
                                   required
                                   value={price_table_name}
                                   onChange={on_change_price_table_name}
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="Price table name"/>
                        </div>
                        <div className={"pt-4"}>
                            <label htmlFor="material" className="block text-sm text-gray-700">
                                Current Price Tables:
                            </label>
                            <select id="price_table" name="price_table" value={selected_price_table} onChange={on_change_selected_price_table} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                { props.data.map((item, index) => {
                                    return (
                                        <option value={item.id} key={index}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button type="button" onClick={onClickOkay} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Okay
                        </button>
                        <button type="button" onClick={onClickCancel} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default NewPriceTableDialog;