import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { AppContext } from 'AppContext';

const Approval = (props) => {

    const { config } = useContext(AppContext);

    const params = useParams();
    const id = params.id;

    const [message ,setMessage ] = useState("...")

    useEffect(() => {
        try {
            const response = axios.get(config.api.base + '/approve/' + id);
            setMessage("The account has been approved.")
        } catch(x) {
            setMessage("Something went wrong, the account could not be approved")
        }
    }, [])

    return(
        <div className="min-h-screen flex justify-center bg-gray-50 pt-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Account Approval Status:
                    </h2>
                    <p className="mt-2 text-center text-md text-gray-600">
                        { message }
                    </p>
                </div>
            </div>
        </div>
    )

}

export default Approval;
