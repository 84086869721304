import React, {useContext, useState, useEffect, useRef} from 'react';
import ApxRendererScene from "../../comps/ApxRendererScene";
import ApxScene from "../../comps/ApxScene";

const Testing = (props) => {

    return(
        <div className={"flex flex-row"}>
            <div className={"pt-28"} >
                <div className={"border-solid border-8"} style={{height: "128px", width: "128px"}}>
                    <ApxScene tag={"test-1"} filename={"http://app.assimilate.com:3030/images/219037a9-1aa5-449e-b3db-bdc9b99ca532/1b9ae6bc-d798-438c-a83a-c99837333010.3mf"}/>
                </div>

            </div>
        </div>

    )

}

export default Testing;