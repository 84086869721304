import React, {useContext, useState, useEffect, useRef} from 'react';
import Dashview from 'paths/admin/dashboard/Dashview';
import axios from 'axios';
import {AppContext} from 'AppContext';
import {Redirect, useHistory, useParams} from "react-router-dom";
import { NavLink } from 'react-router-dom'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, RowDD, Selection,  Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import ApxEditAccount from 'comps/ApxEditAccount';

const Content = (props) => {

    const { identity, config } = useContext(AppContext);
    const history = useHistory();

    const params = useParams();

    const [state, set_state] = useState({ id: "READY"});
    const [redirect, set_redirect] = useState();

    const [price_table, set_price_table] = useState(null);
    const [materials, set_materials] = useState([]);
    const [selected_material, set_selected_material] = useState();
    const [selected_price_table, set_selected_price_table] = useState()

    const [grid_data_source, set_grid_data_source] = useState([])

    const frameReference = useRef();
    const gridReference = useRef();

    useEffect(() => {
        const action = async () => {
            const pricing_api_response = await axios.get(config.api.base + '/pricing/tables/' + params.id );
            set_price_table(pricing_api_response.data);
            const materials_api_response = await axios.get(config.api.base + '/materials')
            set_materials(materials_api_response.data);
            
            material_chosen:
            for (let m=0; m < materials_api_response.data.length; m++) {
                for (let p=0; p < pricing_api_response.data.pricing.length; p++) {
                    if ((materials_api_response.data[m].name === pricing_api_response.data.pricing[p].material)) {
                        set_selected_material(materials_api_response.data[m].id);
                        set_selected_price_table(pricing_api_response.data.pricing[p]);
                        break material_chosen;
                    } 
                }
            }
            
            for(let m = 0; m != materials_api_response.data.length; m++) {
                let has_pricing_table = false;
                for(let i = 0; i != pricing_api_response.data.pricing.length; i++) {
                    if(materials_api_response.data[m].name === pricing_api_response.data.pricing[i].material) {
                        has_pricing_table = true;
                        break;
                    }
                }
                if(has_pricing_table === false) {
                    pricing_api_response.data.pricing.push({ material: materials_api_response.data[m].name, offsets: []});
                }
            }
            
            /** moved up to material_chosen label above - 9/10/2024 ez
            set_selected_material(materials_api_response.data[0].id);
            for(let i=0;i!=pricing_api_response.data.pricing.length; i++) {
                if(materials_api_response.data[0].id === pricing_api_response.data.pricing[i].material) {
                    set_selected_price_table(pricing_api_response.data.pricing[i])
                }
            }
            */
        }
        action();

        // Adjust the height of the data grid so that it fills most of the window:
        gridReference.current.height = window.innerHeight - (frameReference.current.offsetTop + 100);

    }, [])

    useEffect(() => {
        console.log("USE EFFECT: PRICE TABLE SELECTED")
        if(selected_price_table != null) {
            console.log(selected_price_table);
            let normalized_array = [];
            for (let i = 0; i != selected_price_table.offsets.length; i++) {
                let normalized_row = selected_price_table.offsets[i];
                normalized_row.key = i;
                normalized_array.push(normalized_row);
            }

            set_grid_data_source(normalized_array);
        }

    }, [selected_price_table])

    useEffect(() => {
        if(state.id === 'SAVE_PRICING') {
            enter_state_save_pricing();
        }
    }, [state])


    function enter_state_save_pricing() {
        const action = async () => {
            try {
                const data_source = gridReference.current.dataSource;

                let normalized_array = [];
                for(let index=0;index!=data_source.length; index++) {
                    let data_source_row = data_source[index];
                    let normalized_row = {
                        max: data_source_row.max,
                        gray: data_source_row.gray
                    }
                    normalized_array.push(normalized_row);
                }

                selected_price_table.offsets = normalized_array;
                const response = await axios.put(config.api.base + '/pricing/tables/' + params.id, price_table);
                console.log("RESPONSE:")
                console.log(response);
            } catch(x) {

            }
        }
        action();
    }


    const toolbar_options = [
        { text: 'Add'},
        { text: 'Edit'},
        { text: 'Delete'},
    ];

    // Configure the data grid so that it allows editing.
    const edit_settings = { allowEditing: true, allowAdding: true, allowDeleting: true };

    function on_toolbar_clicked(args) {
        console.log("TOOLBAR CLICKED")
        console.log(args);
    }

    function on_change_selected_material(args) {
        set_selected_material(args.target.value);
        for(let i=0;i!=price_table.pricing.length; i++) {
            if(args.target.value === price_table.pricing[i].material) {
                set_selected_price_table(price_table.pricing[i])
            }
        }
    }

    function on_save_pricing() {
        set_state({ id: 'SAVE_PRICING' })
        return;
    }

    if(redirect != null) {
        return <Redirect push to={{ pathname: "/admin/dashboard/users/"+state.data.acct_id, state: {  } }}/>
    }

    const table_name = (price_table === null) ? "" : price_table.name;

    return(
        <div className={"container sm:w-4/5 mx-auto"}>
            <div className={"pb-4"}>
                <div>
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                        Edit Price Table: {table_name}
                    </h2>
                </div>
            </div>
            <div className={"flex"}>
                <div className={"w-2/4 pb-4"}>
                    <div>
                        <label htmlFor="price_table" className="block text-sm font-medium text-gray-700">
                            Material:
                        </label>
                        <select
                            id="price_table"
                            name="price_table"
                            autoComplete="price_table"
                            value={selected_material}
                            onChange={on_change_selected_material}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            {materials.map((item, index) => {
                                return <option key={index} value={item.name}>{item.name}</option>
                            })}
                        </select>
                    </div>

                </div>
                <div className={"flex justify-end items-end pb-4 pl-4 w-full"}>
                    <div>
                        <button onClick={on_save_pricing} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            SAVE
                        </button>
                    </div>
                </div>
            </div>
            {
                selected_material === "Finishing Only" &&
                <div className="mb-2 font-bold text-red-600">
                    Due to the special pricing needed per finish instead of per material for Finishing Only goods, please reach out to the development team for price updates.
                </div>
            }
            <div ref={frameReference}>
                <GridComponent allowRowDragAndDrop={true} ref={gridReference} editSettings={edit_settings} dataSource={grid_data_source} toolbar={toolbar_options} >
                    <ColumnsDirective>
                        <ColumnDirective field={'key'} isPrimaryKey={true} visible={false} headerText={"Key"}/>
                        <ColumnDirective field='max' headerText={"Maximum Volume"}/>
                        <ColumnDirective field='gray' headerText={"Gray Parts Cost"} />
                    </ColumnsDirective>
                    <Inject services={[ Toolbar, Edit, Selection, RowDD ]} />
                </GridComponent>
            </div>
        </div>
    )

}

// toolbarClick={on_toolbar_clicked}
// selected_price_table.offsets

const Pricing = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Pricing;