import React, {useState, useContext, useEffect} from "react";
import Dashview from "../Dashview";
import axios from 'axios';
import { v4 } from 'uuid';
import {useHistory, Prompt, Redirect, useParams} from "react-router-dom";
import { useMachine, asEffect } from '@xstate/react';
import { createMachine, assign } from 'xstate';
import ApxBusy from 'comps/ApxBusy';
import {AppContext} from "AppContext";
import Dropzone from "react-dropzone";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ApxUpload from "../../../../comps/ApxUpload";

const Content = (props) => {

    const { id } = useParams();

    const [order, set_order] = useState({
        customerPurchaseOrderId: "",
        originalInvoiceFilename: "",
        internalInvoiceFilename: "",
        parts: [],
    });

    const [pricing, set_pricing] = useState([]);
    const [materials, set_materials] = useState([]);

    const [orderButtonDisabled, setOrderButtonDisabled] = useState(true);

    const [state, dispatch] = useMachine(() => {
        return createMachine({
            id: "component_state_machine",
            initial: "init",
            context : {
                id: id,
                busy: false,
                upload_dialog_visible: false,
                upload_dialog_percent: 0,
                upload_invoice_dialog_visible: false,
                redirect: null,
            },
            states : {
                init: {
                    invoke: {
                        src: on_init_state_machine,
                        onDone: {
                            target: 'ready'
                        },
                        onError: {

                        }
                    }
                },
                ready: {
                    on: {
                        FILES_DROPPED: {
                            target: 'process_dropped_files',
                            actions: [
                                assign({ busy: (context, event) => true }),
                                assign({
                                    upload_dialog_visible: (context, event) => true,
                                    upload_dialog_percent: (context, event) => 0
                                })
                            ]
                        },
                        REQUEST_UPLOAD_CUSTOM_INVOICE: {
                            target: 'select_custom_invoice_file',
                            actions: [
                                assign({
                                    upload_invoice_dialog_visible: (context, event) => true
                                })
                            ]
                        },
                        REQUEST_PLACE_ORDER: {
                            target: 'process_place_order',
                        }
                    }
                },
                select_custom_invoice_file: {
                    on: {
                        CANCEL_UPLOAD_CUSTOM_INVOICE: {
                            target: "ready",
                            actions: [
                                assign({
                                    upload_invoice_dialog_visible: (context, event) => false
                                })
                            ]
                        },
                        ACCEPT_UPLOAD_CUSTOM_INVOICE: {
                            target: "process_upload_invoice",
                            actions: [
                                assign({
                                    upload_invoice_dialog_visible: (context, event) => false,
                                    upload_dialog_visible: (context, event) => true,
                                })
                            ]
                        }
                    }
                },
                process_upload_invoice: {
                    invoke: {
                        src: on_process_upload_invoice,
                        onDone: {
                            target: 'ready',
                            actions: [
                                assign({
                                    upload_dialog_visible: (context, event) => false,
                                    upload_dialog_percent: (context, event) => 0,
                                })
                            ]
                        },
                        onError: {
                            target: 'ready',
                            actions: [
                                assign({
                                    upload_dialog_visible: (context, event) => false,
                                    upload_dialog_percent: (context, event) => 0,
                                })
                            ]
                        }
                    }
                },
                process_dropped_files: {
                    invoke: {
                        src: on_process_dropped_files,
                        onDone: {
                            target: 'ready',
                            actions: [
                                assign( {
                                    upload_dialog_visible: (context, event) => false,
                                    upload_dialog_percent: (context, event) => 0,
                                })
                            ]
                        },
                        onError: {
                            actions: [
                                assign( {
                                    upload_dialog_visible: (context, event) => false,
                                    upload_dialog_percent: (context, event) => 0
                                })
                            ]
                        }
                    },
                    on: {
                        PROGRESS_UPDATE: {
                            actions: [
                                assign({ upload_dialog_percent: (context, event) => event.data})
                            ]
                        }
                    }
                },
                process_place_order: {
                    invoke: {
                        src: on_process_place_order,
                        onDone: {
                            target: 'process_place_order_success',
                        },
                        onError: {
                            target: 'process_place_order_failure'
                        }
                    }
                },
                process_place_order_success: {
                    entry: [
                        assign({ redirect: (context, event) => "/quotes/submitted"})
                    ]
                },
                process_place_order_failure: {

                }
            }
        })
    })

    const { identity, config } = useContext(AppContext);

    async function on_init_state_machine(context, event) {

        // Retrieve the pricing and materials tables from the
        // server side of the system:
        // Retrieve pricing details from the database:
        let pricing_response = await axios.get(config.api.base + '/pricing');
        set_pricing(pricing_response.data.pricing);

        // Retrieve material specifications from the database:
        let materials_response = await axios.get(config.api.base + '/materials');
        set_materials(materials_response.data);

        // Determine whether or not we are looking at an
        // existing record, or are creating a new one:
        if(id != "new") {
            // We need to load a record from the database, since we are reviewing
            // a record instead of creating a new one:
            let order_response = await axios.get(config.api.base + "/orders/" + id);
            set_order(order_response.data.metadata);

        }
    }

    async function on_process_upload_invoice(context, event) {
        const formData = new FormData();
        const generatedName = v4() + '.' +  event.data[0].name.split('.').pop();
        formData.append("file-1" , event.data[0], generatedName);

        const response = await axios.post(config.api.base + '/temp', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: on_upload_progress
        })

        // We now have the original filename and the generated name
        // that is used to identify the file on the server. We need
        // to add both of those fields to the order record:
        order.originalInvoiceFilename = event.data[0].name;
        order.internalInvoiceFilename = generatedName;
        set_order({...order});

    }

    async function on_process_place_order(context, event) {
        try {
            const record = {
                metadata : {
                    parts: [...order.parts],
                    customerPurchaseOrderId: order.customerPurchaseOrderId,
                    originalInvoiceFilename: order.originalInvoiceFilename,
                    internalInvoiceFilename: order.internalInvoiceFilename
                }
            }
            const response = axios.post(config.api.base + "/orders", record )
        } catch(x) {
            throw "An error occurred while attempting to place this order."
        }
    }

    async function on_process_dropped_files(context, event) {
        const parts = []
        const formData = new FormData();
        for (let i = 0; i != event.data.length; i++) {
            const part_identifier = v4();
            const generatedName = part_identifier + '.' + event.data[i].name.split('.').pop();
            formData.append("file-" + i, event.data[i]);
        }

        const response = await axios.post(config.api.base + '/parts', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: on_upload_progress
        })

        order.parts = order.parts.concat(response.data);
        set_order({...order})
    }

    function on_upload_progress(event) {
        const percentage = 100 * (event.loaded / event.total);
        dispatch({type: "PROGRESS_UPDATE", data: percentage})
    }

    function on_files_dropped(files) {
        dispatch({ type: "FILES_DROPPED", data: files})
    }

    function on_part_changed(args) {
        // Find the part in the collection:
        for(let i=0;i!=order.parts.length;i++) {
            if(order.parts[i].id === args.id) {
                order.parts[i] = args.data;
            }
        }
        set_order({...order})
    }

    function on_assign_invoice_number(args) {

    }

    function on_upload_custom_invoice(args) {
        dispatch({ type: "REQUEST_UPLOAD_CUSTOM_INVOICE", data: {} });
    }

    function on_clicked_upload_invoice_okay(args) {
        dispatch({ type: "ACCEPT_UPLOAD_CUSTOM_INVOICE", data: args })
    }

    function on_clicked_upload_invoice_cancel(args) {
        dispatch({ type: "CANCEL_UPLOAD_CUSTOM_INVOICE", data: {} })
    }

    function on_clicked_place_order(args) {
        dispatch({ type: "REQUEST_PLACE_ORDER", data: {}})
    }

    // If a redirect target is specified, redirect to it:
    if(state.context.redirect != null) {
        return <Redirect to={state.context.redirect}/>
    }

    let order_status = "incomplete";
    if(id !== "new") {
        order_status = "complete";
    }

    if(order.parts.length > 0) {
        let ordersPending = false;
        for(let i=0;i!=order.parts.length;i++) {
            if(!order.parts[i].snapshot_filename) {
                ordersPending = true;
            }
        }
        if(ordersPending === false && orderButtonDisabled !== false) {
            setOrderButtonDisabled(false);
        } else if(ordersPending === true && orderButtonDisabled === false) {
            setOrderButtonDisabled(true);
        }
    }

    function onDeletePart(index) {
        order.parts.splice(index, 1);
        set_order({...order});
    }

    function on_assign_obo(args) {

    }

    return(
        <div className={"container mx-auto"}>
            <div>
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Additive Printing - New Order
                    </h2>
                    <p className="mt-2 text-center text-xl text-gray-600">
                        You can upload 3MF/STL files here to create an order.
                    </p>
                </div>
            </div>
            <div className={"w-full mt-4 mb-4"}>
                <div className={"flex w-full space-x-4"}>
                    <div className={"items-left"}>
                        <label htmlFor="invoiceNumber" className={"block text-sm font-medium text-gray-700"}>
                            Customer Purchase Order #:
                        </label>
                        <input id={"invoiceNumber"} type={"text"} value={order.invoiceNumber} onChange={on_assign_invoice_number} size='25' className={"mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div className={"items-left"}>
                        <label htmlFor="invoiceNumber" className={"block text-sm font-medium text-gray-700"}>
                            Customer Purchase Order:
                        </label>
                        <input id={"invoiceFile"} type={"text"} value={order.originalInvoiceFilename} size='25' readOnly={true} className={"mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"}/>
                    </div>
                    <div className={"flex ml-4 content-end items-end"}>
                        <button className={"standardButton h-10"}  onClick={on_upload_custom_invoice} style={{ marginRight: '5px'}}>
                            <div className={"flex"}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                </svg>
                                <div>Attach Purchase Order</div>
                            </div>
                        </button>
                        <button className={"deleteButton h-10"} disabled={true}  onClick={on_upload_custom_invoice} style={{ marginRight: '5px'}}>
                            <div className={"flex"}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M9 13h6m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div className={"w-full"}>
                {
                    order.parts.map((item, index) => {
                        return(
                            <Part key={item.id} part={item} onDelete={()=>{ onDeletePart(index)}} order_status={order_status} on_changed={(e)=>on_part_changed(e)} pricing={pricing} materials={materials}></Part>
                        );
                    })
                }
            </div>
            <div className={"w-full border border-dashed border-gray-600 bg-indigo-50 h-16 mt-4 mb-4"}>
                <Dropzone  onDrop={on_files_dropped} accept=".stl,.3mf">
                    {({getRootProps, getInputProps}) => (
                        <section style={{ height: "100%"}}>
                            <div style={{ height: "100%"}} className={"flex items-center justify-center"} {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Drag and drop 3MF/STL files here, or click to select files</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className={"flex pt-4 justify-end"}>
                    <button disabled={(order.parts.length > 0) ? false : true  } className={"uploadButton hidden"} onClick={on_clicked_place_order} style={{ marginRight: '5px'}}>
                        Request Quote
                    </button>
                    <button disabled={ orderButtonDisabled } className={"estimateButton"} onClick={on_clicked_place_order} style={{ marginRight: '5px'}}>
                        Place Order
                    </button>
                </div>
            </div>
            <ApxBusy message={"Uploading files"} show={state.context.upload_dialog_visible} percentage={state.context.upload_dialog_percent}/>
            <ApxUpload show={state.context.upload_invoice_dialog_visible} onClickedOkay={on_clicked_upload_invoice_okay} onClickedCancel={on_clicked_upload_invoice_cancel}/>
            <Prompt when={true} message="Any data you have entered on this page will be lost, are you sure you want to leave this page?" />
        </div>
    );
}

const Part = (props) => {

    const { identity, config } = useContext(AppContext);

    const [units] = useState([
        { text: "mm", value: "UNITS_MM"},
        { text: "in", value: "UNITS_IN"}
    ])


    const [finishes] = useState([
        { text: "MJF Finish",       value: "MJF_FINISH"},
        { text: "Vapor Smooth",  value: "VAPOR_SMOOTHING"},
        { text: "Black Dye",        value: "BLACK_DYE"},
        { text: "Vapor Smooth / Black Dye",  value: "AMT_BLACK_DYE"}
    ])

    const [finish_disabled, set_finish_disabled] = useState(false);

    useEffect(()=>{
        // If the snapshot_filename property isn't set, then
        // the part has not yet been fully processed on the
        // server side of the system:
        if(!props.part.snapshot_filename) {
            // So we start polling the server until the part
            // has been fully rendered:
            poll_for_rendered_part(props.part.internal_filename);
        }
    }, [])


    function poll_for_rendered_part(internal_filename) {
        const action = async () => {
            try {
                const result = await axios.get(config.api.base + '/parts/' + internal_filename)
                if(result.status === 204) {
                    setTimeout(() => poll_for_rendered_part(internal_filename), 1000);
                } else if (result.status === 200) {
                    props.part.units = "UNITS_IN";
                    props.part.quantity = 1;
                    props.part.material = "0d96b14d-99d1-42cb-a767-3f10a8d61969";
                    props.part.finish = "MJF_FINISH";
                    props.part.volume = Number(result.data.model_volume) / 16387;
                    props.part.price_per_component = compute_component_price();
                    props.part.price_total = props.part.price_per_component * props.part.quantity;
                    props.part.comments = "";
                    props.part.bounds = result.data.model_bounds;
                    props.part.snapshot_filename = result.data.model_snapshot;
                    props.on_changed({ id: props.part.id, data: {...props.part}})
                    console.log("RESULT IS BACK, VOLUME IS : " + props.part.volume)
                }
            } catch(x) {
                console.log(x);
            }
        }
        action();
    }

    // Determines the price of the part, based on the
    // selected options (material, finish, and so on):
    function compute_component_price() {
        // Find the selected material's id in the price
        // table and use that table for recalculating:
        let relevantOffsets;
        for(let x=0;x!=props.pricing.length;x++) {
            if (props.pricing[x].material === props.part.material) {
                relevantOffsets = props.pricing[x].offsets;
                break;
            }
        }

        let normalized_volume = props.part.volume;
        //console.log("NORMALIZED VOLUME: " + normalized_volume);
        //if(props.part.units === "UNITS_IN") {
        //    console.log("ADJUSTING TO INCHES")
        //    normalized_volume = props.part.volume / 16387;
        //    console.log("NOW IT'S: " + normalized_volume);
        //}

        let total_volume = props.part.quantity * normalized_volume;

        // REC: Removing the roundup of volume, per Neil's request:
        //if(normalized_volume < 1 ) {
        //    total_volume = props.part.quantity;
        //}

        console.log("::::: TOTAL VOLUME CALCULATED :::::");
        console.log("Total volume is = " + total_volume)

        let grayPartsCost = 0;
        let priceByVolume = 0;

        console.log("::::: DETERMINING GRAY PARTS COST :::::");
        for(let index=0;index!=relevantOffsets.length;index++) {
            if(total_volume >= relevantOffsets[index].max) {
                console.log("Total volume is greater than or equal to index " + index + " in the pricing table.")
                grayPartsCost = total_volume * relevantOffsets[index].gray;
                console.log("Gray Parts Cost = " + grayPartsCost)
                break;
            }
        }

        let perPartPrice = grayPartsCost / props.part.quantity;
        if((props.part.material != "0d96b14d-99d1-42cb-a767-3f10a8d61969") && (props.part.finish != "MJF_FINISH")) {
            switch(props.part.finish) {
                case "VAPOR_SMOOTHING":
                    perPartPrice = perPartPrice + (perPartPrice * .20);
                    break;
                case "BLACK_DYE":
                    perPartPrice = perPartPrice + (perPartPrice * .10);
                    break;
                case "AMT_BLACK_DYE" :
                    perPartPrice = perPartPrice + (perPartPrice * .20);
                    break;
            }
        }

        console.log("::::: PER PART PRICE :::::")
        console.log("Per part price is = " + perPartPrice)
        return perPartPrice;

    }

    function on_change_units(args) {
        switch(props.part.units) {
            case "UNITS_IN" :
                console.log("CURRENTLY MEASURING IN INCHES")
                if(args.target.value === "UNITS_MM") {
                    console.log("SWITHCING TO MM")
                    props.part.volume = props.part.volume * 16387;
                }
                break;
            case "UNITS_MM" :
                if(args.target.value === "UNITS_IN") {
                    props.part.volume = props.part.volume / 16387;
                }
                break;
        }

        props.part.units = args.target.value;
        props.part.price_per_component = compute_component_price();
        props.part.price_total = props.part.price_per_component * props.part.quantity;

        props.on_changed({ id: props.part.id, data: {...props.part}})
    }

    function on_change_material(args) {
        props.part.material = args.target.value;
        props.part.price_per_component = compute_component_price();
        props.part.price_total = props.part.price_per_component * props.part.quantity;
        props.on_changed({ id: props.part.id, data: { ...props.part}})
    }

    function on_change_finish(args) {
        props.part.finish = args.target.value;
        props.part.price_per_component = compute_component_price();
        props.part.price_total = props.part.price_per_component * props.part.quantity;
        props.on_changed({id: props.part.id, data: {...props.part}})
    }

    function on_change_quantity(args) {
        props.part.quantity = args.target.value;
        props.part.price_per_component = compute_component_price();
        props.part.price_total = props.part.price_per_component * props.part.quantity;
        props.on_changed({ id: props.part.id, data: { ...props.part}})
    }

    function on_change_comments(args) {
        props.part.comments = args.target.value;
        props.on_changed({ id: props.part.id, data: { ...props.part}});
    }

    let display_volume = props.part.volume;
    //if(props.part.units === "UNITS_IN") {
    //    display_volume = props.part.volume / 16387;
    //}

    let display_bounds = "0,0,0";
    if(props.part.bounds) {
        display_bounds = Number(props.part.bounds.x).toFixed(2);
        display_bounds += ' / ';
        display_bounds += Number(props.part.bounds.y).toFixed(2);
        display_bounds += ' / ';
        display_bounds += Number(props.part.bounds.z).toFixed(2);
    }

    if(finish_disabled === false && props.part.material === "0d96b14d-99d1-42cb-a767-3f10a8d61969") {
        set_finish_disabled(true);
    }

    if(finish_disabled === true && props.part.material != "0d96b14d-99d1-42cb-a767-3f10a8d61969") {
        set_finish_disabled(false);
    }

    if(!props.part.snapshot_filename) {
        return (
            <div className={"flex flex-row border-2 border-black mt-2"}>
                <div style={{ width: "196px", height: "196px"}}>
                    <img src={"/loading.gif"} style={{ height: "100%"}} />
                </div>
                <div className={"flex-col text-center w-full pt-8"}>
                    <div className={"text-3xl"}>{props.part.original_filename}</div>
                    <div>The server is currently processing this model.</div>
                    <div>This may take seconds or minutes, depending on the size of the model</div>
                    <Dots size={32}/>
                </div>
            </div>
        )
    } else {
        // The fully-qualified path to the location of the snapshot image for this part:
        let snapshot_path = config.api.base + '/temp/' + props.part.snapshot_filename;
        if(props.order_status === "complete") {
            snapshot_path = config.api.base + '/orders/' + identity.userAcctId + '/' + props.part.snapshot_filename;
        }

        return (
            <div className={"flex flex-row border-2 border-black mt-2"}>
                <div style={{ width: "196px", height: "196px"}}>
                    <img src={snapshot_path} style={{ height: "100%"}} />
                </div>
                <div className={"flex flex-col flex-grow space-y-2"}>
                    <div className={"flex flex-row flex-nowrap justify-center items-center space-x-4 pt-2 pl-4"}>
                        <div>
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                Units
                            </label>
                            <select id="units" name="units" value={props.part.units} onChange={on_change_units} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                { units.map((item, index) => {
                                    return (
                                        <option value={item.value} key={index}>{item.text}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="material" className="block text-sm font-medium text-gray-700">
                                Material
                            </label>
                            <select id="material" name="material" value={props.part.material} onChange={on_change_material} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                { props.materials.map((item, index) => {
                                    return (
                                        <option value={item.id} key={index}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="finish" className="block text-sm font-medium text-gray-700">
                                Finish
                            </label>
                            <select id="finish" disabled={finish_disabled} name="finish" value={props.part.finish} onChange={on_change_finish} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                { finishes.map((item, index) => {
                                    return (
                                        <option value={item.value} key={index}>{item.text}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div>
                            <label htmlFor={'volume'} className={"block text-sm font-medium text-gray-700"}>Volume</label>
                            <input id={"volume"} type={"text"} size={10} readOnly={true} value={display_volume.toFixed(2)}  className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                        </div>
                        <div>
                            <label htmlFor={'bounds'} className={"block text-sm font-medium text-gray-700 whitespace-nowrap"}>Bounding box (x/y/z):</label>
                            <input id={"bounds"} type={"text"} readOnly={true} value={display_bounds}  className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                        </div>
                        <div>
                            <label htmlFor="quantity" className={"block text-sm font-medium text-gray-700"}>
                                Quantity
                            </label>
                            <input id={"quantity"} type={"number"} value={props.part.quantity} onChange={on_change_quantity} className={"mt-1 w-24 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                        </div>
                        <div>
                            <label htmlFor={'partcost'} className={"block text-sm font-medium text-gray-700 whitespace-nowrap"}>
                                Cost per part
                            </label>
                            <input id={"partcost"} size={8} type={"text"} readOnly={true} value={"$" + props.part.price_per_component.toFixed(2)} className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                        </div>
                        <div className={"pr-16"}>
                            <label htmlFor="total price" className={"block text-sm font-medium text-gray-700"}>
                                Price
                            </label>
                            <input id={"price"} size={8} type={"text"} readOnly={true} value={"$" + props.part.price_total.toFixed(2)} className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                        </div>
                        <div className={"flex justify-end flex-grow justify-end pt-2 h-full color-indigo-500"}>
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={props.onDelete} className="h-6 w-6 stroke-current text-gray-500 hover:text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </div>
                    </div>
                    <div className={"pr-12 pl-12 pt-2 pb-2"}>
                        <label className="block">
                            <span className="text-gray-700 whitespace-nowrap">Special instructions / comments:</span>
                            <textarea className="form-textarea mt-1 pl-2 block w-full" rows="3" value={props.part.comments} onChange={on_change_comments}/>
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}

const Editor = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Editor;
