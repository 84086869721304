import React, { useContext } from "react";
import { Redirect } from 'react-router-dom';
import AppRoute from 'routes/AppRoute';
import { AppContext } from 'AppContext';

const SecRoute = (props) => {
    const { identity } = useContext(AppContext);
    if(!identity) {
        return <Redirect to={{ pathname: '/sign-in', state: { from: props.location.pathname }} }/>
    } else {
        return <AppRoute {...props}/>
    }
}

export default SecRoute;