const common = {

}

const development = {
    api : {
        base: 'http://app.assimilate.com:3030'
    },
    storage: {
        host: "http://app.assimilate.com:3030"
    }
}

const production = {
    api: {
        base: 'https://3ds.abcorp.com/api'
    },
    storage: {
        host: "https://3ds.abcorp.com/api"
    }
}

var config = {};
switch(process.env.NODE_ENV) {

    case "production":
        config = {...common, ...production};
        break;

    case "development":
        config = {...common, ...development};
        break;

    default:
        break;
}

export default config;