import React from 'react';

const ApxBusy = (props) => {

    return(
        <div className={"fixed z-10 inset-0 overflow-y-auto " + ((props.show === true) ? "visible" : "hidden")}>
            <div className={"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"}>
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className={"inline-block align-center bg-white p-8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className={"flex w-full justify-center"}>
                        { props.message }
                    </div>
                    <div className="h-3 relative max-w-xl rounded-full overflow-hidden">
                        <div className="w-full h-full bg-gray-200 absolute"></div>
                        <div id="bar" className="h-full bg-green-500 relative" style={{ width: props.percentage + "%"}}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApxBusy;
