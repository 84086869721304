import React, {useContext, useState, useEffect, useRef} from 'react';
import Dashview from 'paths/admin/dashboard/Dashview';
import axios from 'axios';
import {AppContext} from 'AppContext';
import {Redirect, useHistory} from "react-router-dom";
import { NavLink } from 'react-router-dom'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import ApxEditAccount from 'comps/ApxEditAccount';
import NewPriceTableDialog from "comps/NewPriceTableDialog";

const Content = (props) => {

    const { identity, config } = useContext(AppContext);
    const history = useHistory();

    const [state, set_state] = useState({ id: "READY"});
    const [redirect, set_redirect] = useState(null)
    const [show_price_table_dialog, set_show_price_table_dialog] = useState(false);
    const [price_tables, set_price_tables] = useState([])

    const frameReference = useRef();
    const gridReference = useRef();

    useEffect(() => {

        const action = async () => {
            const pricing_api_response = await axios.get(config.api.base + '/pricing/tables');
            set_price_tables(pricing_api_response.data);
            console.log(pricing_api_response.data);
        }
        action();

        gridReference.current.height = window.innerHeight - (frameReference.current.offsetTop + 100);

    }, [])

    useEffect( () => {
        switch(state.id) {
            case "EDIT" :
                set_redirect(true);
                break;
            case 'REQUEST_CREATE_PRICE_TABLE':
                set_show_price_table_dialog(true);
                break;
            case 'EXECUTE_CREATE_PRICE_TABLE':
                on_execute_create_price_table(state.data);
                break;
        }

    }, [state])

    const toolbar_options = [
        { text: 'Create', prefixIcon: 'e-add', align: 'Right', id: 'create'},
        { text: 'Edit', prefixIcon: 'e-edit',  align: 'Right', id: 'edit'},
        { text: 'Delete', align: 'Right', id: 'delete'},
    ];

    function on_toolbar_clicked(args) {
        if(args.item.id === "edit") {
            // Get the selected account from the grid control:
            const selectedIndexes = gridReference.current.getSelectedRowIndexes();
            // Set the current state so that the form redirects
            // to the price table editor for the specified table:
            set_state({ id: 'EDIT', data: price_tables[selectedIndexes[0]]})
        } else if(args.item.id === 'create') {
            set_state( { id: 'REQUEST_CREATE_PRICE_TABLE', data: {}})
        }
    }

    function on_execute_create_price_table(data) {
        const action = async () => {
            try {

                let proposed_price_table = {
                    name: data.price_table_name,
                    pricing: []
                }

                for(let i=0;i!=price_tables.length;i++) {
                    if(price_tables[i].id === data.selected_price_table) {
                        proposed_price_table.pricing = [...price_tables[i].pricing]
                        break;
                    }
                }

                const api_response = await axios.post(config.api.base + '/pricing/tables', proposed_price_table)

                const action = async () => {
                    const pricing_api_response = await axios.get(config.api.base + '/pricing/tables');
                    set_price_tables(pricing_api_response.data);
                    console.log(pricing_api_response.data);
                }
                action();

            } catch(x) {

            }

        }
        action();
    }

    function on_price_table_dialog_okay(args) {
        // The price table name and selected template
        // will be provided by the dialog when it's closed:
        set_show_price_table_dialog(false);
        set_state({ id: "EXECUTE_CREATE_PRICE_TABLE", data: args})
    }

    function on_price_table_dialog_cancel() {
        set_show_price_table_dialog(false);
    }

    // used for testing - 9/10/2024 ez
    // <div><button className="standardButton" onClick={on_click_testing}>Length of Price Table</button></div>
    function on_click_testing() {
        console.log(price_tables.length);
        console.log(price_tables);
    }

    // was used to update pricing tables from using GUID materials to plain text materials - 9/10/2024
    // <div><button className="standardButton" onClick={on_click_materials_update}>Pricing Table Material Change!</button></div>
    function on_click_materials_update() {
        const action = async () => {
            for(let i = 0; i != price_tables.length; i++) {
                console.log("name: " + price_tables[i].name);
                let current_pricing = price_tables[i].pricing;
                let runningString = "";
                for (let j = 0; j < current_pricing.length; j++) {
                    switch (current_pricing[j].material) {
                        case "0d96b14d-99d1-42cb-a767-3f10a8d61969":
                            current_pricing[j].material = "HP Polypro";
                            break;
                        case "e9a780a7-e878-46aa-8ff8-65c9991233a6": 
                            current_pricing[j].material = "HP-PA12";
                            break;
                        case "9eec4c4e-ceb6-4ba9-83c0-405ad3207874": 
                            current_pricing[j].material = "BASF-TPU Ultrisint";
                            break;
                        case "630f0469-63d1-4d40-82bd-69654ae4c494":
                            current_pricing[j].material = "PA12CB-Color";
                            break;
                        case "832a93bc-d16a-4a93-b6d9-419e7a89b021":
                            current_pricing[j].material = "HP-PA11";
                            break;
                        default:
                            runningString = runningString + current_pricing[j].material + " "; 
                    }
                }
                if (runningString !== "") {
                    console.log(price_tables[i].name + ": " + runningString);
                    runningString = "";
                } else {
                    const response = await axios.put(config.api.base + '/pricing/tables/' + price_tables[i].id, price_tables[i]);
                    console.log(response);
                }
                
            }
            console.log(price_tables);
        }
        action();
    }

    if(redirect != null) {
        return <Redirect push to={{ pathname: "/admin/dashboard/pricing/"+state.data.id, state: {  } }}/>
    }

    return(
        <div className={"container sm:w-4/5 mx-auto"}>
            <div className={"pb-4"}>
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                    Price Tables:
                </h2>
            </div>
            <div ref={frameReference}>
                <GridComponent ref={gridReference} dataSource={price_tables} toolbar={toolbar_options} toolbarClick={on_toolbar_clicked}>
                    <ColumnsDirective>
                        <ColumnDirective field='id' headerText={"Id"}/>
                        <ColumnDirective field='name' headerText={"Price Table Name"} />
                    </ColumnsDirective>
                    <Inject services={[ Toolbar ]} />
                </GridComponent>
            </div>
            <NewPriceTableDialog data={price_tables} show={show_price_table_dialog} onOkay={on_price_table_dialog_okay} onCancel={on_price_table_dialog_cancel}/>
        </div>
    )

}

const Pricing = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Pricing;