import React, { useState, useContext, useEffect } from 'react';
import { useHistory, NavLink, Redirect } from 'react-router-dom';
import axios from 'axios';
import { AppContext } from 'AppContext';
import * as QueryString from 'query-string';

const Welcome = (props) => {

    const [initialized, setInitialized] = useState({ status: false, exists: false});

    // Retrieve global configuration from application context:s
    const {config} = useContext(AppContext);
    // Retrieve history router for redirecting the user:
    const [history] = useState(useHistory());
    // Retrieve the query string and parse it into an object:
    const params = QueryString.parse(props.location.search);
    // Go to the API and check on this account:
    useEffect(()=>{

        const action = async () => {
            try {
                const response = await axios.get(config.api.base + '/exists/' + params.email)
                if (response.status === 200) {
                    setInitialized({status: true, exists: true})
                }
            } catch(x) {
                setInitialized({ status: true, foo: 'bar', exists: false})
            }
        }

        action();

    }, [])

    if(initialized.status === true) {
        if (initialized.exists === true) {
            return (<Redirect push to={'/sign-in?username=' + params.email}/>)

        } else {
            const path = "/sign-up?firstname=" +
                (params.firstname ? params.firstname : "") +
                "&lastname=" +
                (params.lastname  ? params.lastname : "") +
                "&email=" +
                (params.email ? params.email : "") +
                "&phone=" +
                (params.phone ? params.phone : "");

            console.log("REDIRECT PATH IS:")
            console.log(path);

            return (<Redirect push to={path}/>)
        }
    } else {
        return null;
    }
}

export default Welcome;
